import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useEffect, useState } from "react";
import { showMixinToast, showModalConfirm, showModalMessage } from "../../utils/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, startLoading } from "../../actions/ui";
import { AccessProps, RoleTypes, SetRoleProps } from '../../interfaces/AuthInterfaces';
import { Input } from "../molecules/Input";
import { starApproveUpdateAccessQuery, starDeleteMemberQuery } from "../../queries/AuthQueries";
import { StoreProps } from "../../interfaces/ReducerInterfaces";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 90%;
    max-width: 600px;
    max-height: 80%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

interface Props {
    data: AccessProps;
    setVisible: React.Dispatch<React.SetStateAction<AccessProps | null>>
}

export const SetAccessRoleModal = ({ data, setVisible }: Props) => {

    const dispatch = useDispatch();

    const [isMember] = useState(data.role !== 'None');

    const { token } = useSelector((state: StoreProps) => state.auth);

    const [form, setForm] = useState<RoleTypes>(data.role);

    const handleFormConfirm = () => {

        showModalConfirm({
            title: '¿Estas seguro?',
            description: isMember ? `Estas a punto de modificar el rol de ${data.name.toUpperCase()} a ${form!.toUpperCase()}.` : `Estas a punto de aprobar el acceso a ${data.name.toUpperCase()} con el rol de ${form!.toUpperCase()}.`,
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            confirmButtonColor: colors.ok,
            cancelButtonColor: colors.danger,
            handleConfirm: async () => {

                dispatch(startLoading())

                const body: SetRoleProps = {
                    email: data.email,
                    role: form,
                }

                await starApproveUpdateAccessQuery(body, token!, dispatch);

                showMixinToast(isMember ? `Rol modificado` : `Acceso aprobado`, 'success');

                setVisible(null);

            }
        })

    }

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        {isMember ? 'Modificar rol' : 'Asignar rol'}
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(null) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <Input
                    label="Rol"
                    type="select"
                    value={form}
                    onChange={(e) => {

                        if (e.target.value === 'Sin rol asignado') {

                            setForm('None' as RoleTypes);

                        } else {

                            setForm(e.target.value as RoleTypes);

                        }

                    }}
                >
                    <option value={'Sin rol asignado'}>Sin rol asignado</option>
                    <option value={'Administrador'}>Administrador</option>
                    <option value={'Proveedor'}>Proveedor</option>
                </Input>

                <Typography
                    size='14px'
                    style={{
                        marginTop: 20,
                        width: '100%',
                        whiteSpace: 'normal',
                        lineHeight: 2,
                    }}
                >
                    - <span style={{ fontWeight: 'bold' }}>ADMINISTRADOR:</span> Acceso total.
                </Typography>

                <Typography
                    size='14px'
                    style={{
                        marginTop: 5,
                        width: '100%',
                        whiteSpace: 'normal',
                        lineHeight: 2,
                    }}
                >
                    - <span style={{ fontWeight: 'bold' }}>PROVEEDOR:</span> Ver productos y actualizar precios.
                </Typography>

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={isMember ? 'Modificar rol' : 'Aprobar acceso'}
                    secondaryButtonText={'Cancelar'}
                    primaryDisabled={form === data.role || form === 'None'}
                    secondaryDisabled={false}
                    onClickPrimary={() => { handleFormConfirm() }}
                    onClickSecondary={() => {
                        setVisible(null);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
