import { finishLoading, startLoading } from '../actions/ui';
import { ProductProps } from '../interfaces/ProductInterfaces';
import { DeleteSoldProps } from '../interfaces/SoldInterfaces';
import { startDeleteProductQuery } from '../queries/ProductQueries';
import { showModalConfirm } from '../utils/Alerts';

export const handleDeleteProduct = (product: ProductProps, dispatch: any, token: string | null, setProductDetailsModalVisible: React.Dispatch<React.SetStateAction<ProductProps | null>>) => {

    const handleDelete = async () => {

        dispatch(startLoading())

        const body: DeleteSoldProps = {
            id: product.id
        }

        await startDeleteProductQuery(body, token!, dispatch);

        setProductDetailsModalVisible(null)

        dispatch(finishLoading())

    }

    showModalConfirm({
        title: '¿Estas seguro?',
        description: `Estas a punto de eliminar eliminar de manera permanente el producto [${product?.name.toUpperCase()}].`,
        icon: 'warning',
        confirmButtonText: 'Si, estoy seguro',
        handleConfirm: () => { handleDelete() }
    })


}
