import { ProductProps } from "../interfaces/ProductInterfaces";
import { SoldProps } from "../interfaces/SoldInterfaces";

export const initialJsonProductProps: ProductProps = {
    id: 0,
    barCode: null,
    brandId: 0,
    image: null,
    name: '',
    model: '',
    price: 0,
    stockAmount: 0,
    customProfit: null
}

export const initialJsonSoldProps: SoldProps = {
    id: 0,
    date: 0,
    productId: 0,
    author: '',
    amount: 1,
    unitPrice: 0,
    profit: 0
}
