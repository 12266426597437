import { ScreensProps } from "../interfaces/NavigationInterfaces";

export const screens: ScreensProps = {
    signin: 'iniciar-sesion',
    signup: 'registrarse',
    forgotPass: 'olvide-mi-contrasena',
    authStatus: 'estado',
    home: 'inicio',
    statistics: 'estadisticas',
    profile: 'perfil',
}