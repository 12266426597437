import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Home01Icon, UserIcon, Analytics01Icon } from "@hugeicons/react-pro";
import { screens } from "../../constants/NavigationConstants";
import { getScreenName } from "../../helpers/NavigationHelper";
import { StoreProps } from "../../interfaces/ReducerInterfaces";

const SideBar = styled.div`
    width: 100%;
    height: 70px;
    box-shadow: none; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: ${colors.dark};
    box-shadow: 0px 4px 12px 0px #00000025;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;

    & > h1 {
        visibility: hidden;
        position: absolute;

        @media ${device.lg} {
            color: ${colors.light};
            text-transform: capitalize;
            visibility: visible;
            position: unset;
            padding-left: 10px;
            padding-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        } 

        @media ${device.md} {
            font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }

    @media ${device.lg} {
        justify-content: center;
    } 


    @media ${device.md} {
        padding-left: 30px;
        padding-right: 30px;
    } 

    @media ${device.sm} {
        padding-left: 20px;
        padding-right: 20px;
    } 

`

const NavContainer = styled.div`
    width: 700px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    @media ${device.md} {
        width: 500px;
    } 

    @media ${device.sm} {
        width: 90%;
        max-width: 400px;
    } 
`

const NavButton = styled.button`
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    font-size: 20px;
    border: transparent 1px solid;
    border-radius: 3px;
    background-color: transparent;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.3 ease;

    @media ${device.lg} {
        width: 200px;
        font-size: 16px;
        padding: 10px;
    } 

    @media ${device.md} {
        width: 150px;
        font-size: 16px;
    } 

    @media ${device.sm} {
        width: 125px;
        font-size: 14px;
    } 

    @media ${device.xs} {
        width: 120px;
        font-size: 13px;
    } 


    &:hover {
        cursor: pointer;
        border: ${colors.info} 1px solid;
        box-shadow: 0px 0px 6px #00000026;

        @media ${device.lg} {
            border: 1px solid transparent;
            box-shadow: none;
        } 
    }

    & > hr {
        width: 15px;
        border: none;

        @media ${device.lg} {
            width: 10px;
        }
        
        @media ${device.md} {
            display: none;
        } 
    }

    & > svg {

        @media ${device.lg} {
            width: 20px;
            height: 20px;
        }

        @media ${device.md} {
            display: none;
        } 
    }


`



export const Header = () => {

    const { role } = useSelector((state: StoreProps) => state.auth);

    const history = useHistory();

    const location = useLocation()

    const [titleState] = useState(getScreenName(location.pathname));

    return (

        <SideBar>

            {/* <Notifications /> */}

            <NavContainer>

                <NavButton
                    style={{ color: titleState === screens.home ? colors.primary : colors.light, fontWeight: titleState === screens.home ? 600 : 'unset' }}
                    onClick={() => {
                        history.push(`/${screens.home}`)
                    }}
                >
                    <>
                        <Home01Icon size={25} color={titleState === screens.home ? colors.primary : colors.light} />
                        <hr />
                        {screens.home}
                    </>
                </NavButton>

                {
                    role === 'Administrador' && (

                        <NavButton
                            style={{ color: titleState === screens.statistics ? colors.primary : colors.light, fontWeight: titleState === screens.statistics ? 600 : 'unset' }}
                            onClick={() => {
                                history.push(`/${screens.statistics}`)
                            }}
                        >
                            <>
                                <Analytics01Icon size={25} color={titleState === screens.statistics ? colors.primary : colors.light} />
                                <hr />
                                {screens.statistics}
                            </>
                        </NavButton>

                    )
                }

                <NavButton
                    style={{ color: titleState === screens.profile ? colors.primary : colors.light, fontWeight: titleState === screens.profile ? 600 : 'unset' }}
                    onClick={() => {
                        history.push(`/${screens.profile}`)
                    }}
                >
                    <>
                        <UserIcon size={25} color={titleState === screens.profile ? colors.primary : colors.light} />
                        <hr />
                        {screens.profile}
                    </>
                </NavButton>

            </NavContainer>

        </SideBar>

    )
}
