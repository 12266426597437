import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { useDispatch, useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { LoadingComponent } from "../molecules/LoadingComponent";
import { showMixinToast, showModalConfirm } from "../../utils/Alerts";
import { ActionButtonsProps, ProductProps } from "../../interfaces/ProductInterfaces";
import { Input } from "../molecules/Input";
import { formatPrice } from "../../utils/Utilities";
import { BoxContainer } from "./BoxContainer";
import { InformationCircleIcon, MoneyBag02Icon, SecurityIcon } from "@hugeicons/react-pro";
import { OptionButtons } from "./OptionButtons";
import { CollapsableSection } from "../molecules/CollapsableSection";
import { ActionInputButton } from "../atoms/ActionInputButton";
import { startLoading } from "../../actions/ui";
import { startUpdateStockQuery } from "../../queries/ProductQueries";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 95%;
    height: 95%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    padding-top: 120px;
    background-color: white;
    overflow-y: scroll;
    white-space: nowrap;
    position: relative;

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 93%;
    max-height: 95%;
    background-color: ${colors.light};
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 25px 50px;
    border-bottom: 1px solid ${colors.border};
    position: fixed;
    border-radius: 10px 10px 0px 0px;
    left: 10px;
    right: 10px;
    top: 2.5%;
    margin: auto;
    z-index: 2;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const SubContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 
`

const DetailTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
    padding: 0px 5px;

    & > h1 {
        white-space: normal;
        text-align: left;
    }
`

interface Props {
    data: ProductProps;
    detailsOnly?: boolean;
    setData: React.Dispatch<React.SetStateAction<ProductProps | null>>;
    onModifyProduct?: (product: ProductProps) => void;
    onDeleteProduct?: (product: ProductProps) => void;
    onUpdatePriceProduct?: (product: ProductProps) => void;
    onUpdateProfitManualProduct?: (product: ProductProps) => void;
    onUpdateStockProduct?: (product: ProductProps) => void;
    onSellProduct?: (product: ProductProps) => void;
}

export const ProductDetailsModal = ({ data, detailsOnly, setData, onModifyProduct, onDeleteProduct, onUpdatePriceProduct, onUpdateStockProduct, onSellProduct, onUpdateProfitManualProduct }: Props) => {

    const { brands, checkingBrands, profit } = useSelector((state: StoreProps) => state.fallherramientas);

    const { role, token } = useSelector((state: StoreProps) => state.auth);

    const dispatch: any = useDispatch();

    const handleShare = async () => {

        if (navigator.share) {

            const json: ShareData = {
                title: data.name,
                text: `Detalles del producto: \n\n- Producto: *${data.name.toUpperCase()}*\n- Modelo: *${data.model}*\n- Valor de contado: *$${data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? formatPrice(data.price + data.customProfit) : formatPrice(data.price * (1 + (profit! / 100)))}*\n${data.image ? '\n IMAGEN DEL PRODUCTO:\n' + data.image : ''}`,
            }

            try {
                await navigator.share(json);
                showMixinToast('Informacion compartida', 'success', 'bottom');
            } catch (error) {
            }

        } else {
            showMixinToast('Información no compartida', 'error', 'bottom');
        }
    };

    const actionButtonsData: ActionButtonsProps[] = [
        {
            id: 0,
            name: 'Compartir datos del producto',
            type: 'dark',
            // show: role === 'Administrador' && (isMobile || isTablet),
            // handleClick: () => { if (role === 'Administrador' && (isMobile || isTablet)) { handleShare() } }
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') { handleShare() } }
        },
        {
            id: 1,
            name: 'Indicar como vendido',
            type: 'dark',
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') { onSellProduct && onSellProduct(data) } }
        },
        {
            id: 2,
            name: 'Agregar mas al stock',
            type: 'dark',
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') { onUpdateStockProduct && onUpdateStockProduct(data) } }
        },
        {
            id: 3,
            name: `Actualizar precio de costo`,
            type: 'dark',
            show: true,
            handleClick: () => { onUpdatePriceProduct && onUpdatePriceProduct(data) }
        },
        {
            id: 4,
            name: 'Modificar este producto',
            type: 'dark',
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') { onModifyProduct && onModifyProduct(data) } }
        },
        {
            id: 5,
            name: 'Eliminar este producto',
            type: 'dark',
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') { onDeleteProduct && onDeleteProduct(data) } }
        }
    ]

    const handleSetNoStock = async () => {

        showModalConfirm({
            title: '¿Estas seguro?',
            description: `Estas a punto de indicar que no tenes stock de este producto.`,
            icon: 'warning',
            confirmButtonText: 'Si, estoy seguro',
            handleConfirm: async () => {

                dispatch(startLoading())

                const body = {
                    id: data.id,
                    stockAmount: 0,
                }

                await startUpdateStockQuery(body, token!, dispatch);

                setData({ ...data, stockAmount: 0 })

                showMixinToast('Stock actualizado', 'success');


            }
        })

    }

    return (

        <ShadowContainer >

            <ModalCardContainer>

                {
                    checkingBrands ?

                        (<LoadingComponent />)

                        :

                        (
                            <>

                                <HeaderContainer>

                                    <Typography
                                        size='28px'
                                        bold
                                    >
                                        {detailsOnly ? 'Producto vendido' : 'Producto encontrado'}
                                    </Typography>

                                    <Button
                                        type="icon"
                                        icon={require('../../images/plus-primary-dark.png')}
                                        onClick={() => { setData(null) }}
                                        style={{ transform: 'rotate(45deg)', zIndex: 2 }}
                                    />

                                </HeaderContainer>

                                {!detailsOnly && <OptionButtons data={actionButtonsData.filter((value) => { return value.show === true })} />}

                                <SubContainer>

                                    <BoxContainer style={{ backgroundColor: colors.light, borderColor: colors.grey_normal }}>

                                        <DetailTitleContainer>

                                            <InformationCircleIcon size={20} color={colors.primary} />

                                            <Typography
                                                size='20px'
                                                bold
                                                style={{ marginLeft: 10 }}
                                            >
                                                Detalles del producto
                                            </Typography>

                                        </DetailTitleContainer>

                                        {
                                            data.image !== null && (

                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                    <img src={data.image} alt="" style={{ height: 150, width: 150, marginBottom: 20, objectFit: 'cover' }} />

                                                </div>

                                            )
                                        }

                                        <Input
                                            label="Marca"
                                            type="select"
                                            value={brands.find((value) => { return value.id === data.brandId })?.name}
                                            theme="dark"
                                            readOnly
                                            onChange={() => { }}
                                        >
                                            <option value={0}>SELECCIONE UNA MARCA</option>
                                            {
                                                !checkingBrands && brands.map((brand) => {

                                                    return <option key={brand.id} value={brand.id}>{brand.name}</option>
                                                })
                                            }
                                        </Input>


                                        <Input
                                            type="text"
                                            label="Nombre"
                                            value={data.name}
                                            readOnly
                                            onChange={() => { }}
                                        />

                                        <Input
                                            type="text"
                                            label="Modelo"
                                            value={data.model}
                                            readOnly
                                            onChange={() => { }}
                                        />

                                        {
                                            role === 'Administrador' ? (

                                                <>

                                                    <Input
                                                        type="number"
                                                        label="Cantidad disponible en stock"
                                                        value={data.stockAmount}
                                                        readOnly
                                                        onChange={() => { }}
                                                        clearButton={
                                                            <ActionInputButton
                                                                text={data.stockAmount === 0 ? "Agregar stock" : "Indicar sin stock"}
                                                                onClick={() => {
                                                                    if (data.stockAmount === 0) {
                                                                        onUpdateStockProduct && onUpdateStockProduct(data)
                                                                    } else {
                                                                        handleSetNoStock()
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    <Input
                                                        type="text"
                                                        label="Código de barras"
                                                        value={data.barCode && data.barCode.trim() !== '' ? data.barCode : 'Sin código'}
                                                        readOnly
                                                        onChange={() => { }}
                                                    />

                                                </>

                                            ) : (

                                                <Input
                                                    type="number"
                                                    label={`Precio de costo`}
                                                    value={`AR$ ${formatPrice(data.price)}`}
                                                    readOnly
                                                    onChange={() => { }}
                                                />

                                            )
                                        }

                                    </BoxContainer>

                                    {
                                        role === 'Administrador' && (

                                            <div style={{ width: '100%' }}>

                                                <BoxContainer style={{ backgroundColor: colors.light, borderColor: colors.grey_normal, height: 'fit-content' }}>


                                                    <DetailTitleContainer>

                                                        <MoneyBag02Icon size={20} color={colors.primary} />

                                                        <Typography
                                                            size='20px'
                                                            bold
                                                            style={{ marginLeft: 10 }}
                                                        >
                                                            Precios
                                                        </Typography>

                                                    </DetailTitleContainer>

                                                    <Input
                                                        type="text"
                                                        label={`Precio en efectivo`}
                                                        value={profit !== null ? `AR$ ${data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? formatPrice(data.price + data.customProfit) : formatPrice(data.price * (1 + (profit! / 100)))}` : '-'}
                                                        readOnly
                                                        onChange={() => { }}
                                                        clearButton={
                                                            <ActionInputButton
                                                                text={data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? "Cambiar precio" : "Asignar precio"}
                                                                onClick={() => { onUpdateProfitManualProduct && onUpdateProfitManualProduct(data) }}
                                                            />
                                                        }
                                                    />

                                                    <Input
                                                        type="number"
                                                        label={`Precio en transferencia con 5%`}
                                                        value={profit !== null ? `AR$ ${data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? formatPrice((data.price + data.customProfit) * 1.05) : formatPrice((data.price * (1 + (profit! / 100))) * 1.05)}` : '-'}
                                                        readOnly
                                                        onChange={() => { }}
                                                    />

                                                    <Input
                                                        type="number"
                                                        label={`Precio con 21% IVA`}
                                                        value={profit !== null ? `AR$ ${data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? formatPrice((data.price + data.customProfit) * 1.21) : formatPrice((data.price * (1 + (profit! / 100))) * 1.21)}` : '-'}
                                                        readOnly
                                                        onChange={() => { }}
                                                    />

                                                </BoxContainer>

                                                <CollapsableSection
                                                    containerStyle={{ marginTop: 10, border: `1px solid ${colors.grey_normal}` }}
                                                    headerElement={

                                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                                            <SecurityIcon size={20} color={colors.primary} />

                                                            <Typography
                                                                size='20px'
                                                                bold
                                                                style={{ marginLeft: 10 }}
                                                            >
                                                                Mas información
                                                            </Typography>

                                                        </div>

                                                    }
                                                    bodyElement={

                                                        <>

                                                            <Input
                                                                type="number"
                                                                label={`Precio de costo`}
                                                                value={`AR$ ${formatPrice(data.price)}`}
                                                                readOnly
                                                                onChange={() => { }}
                                                            />

                                                            <Input
                                                                type="number"
                                                                label={`Ganancia actual por unidad`}
                                                                value={data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? `AR$ ${formatPrice(data.customProfit)} (Ganancia indicada manual)` : `AR$ ${formatPrice(data.price * (profit! / 100))} (${profit}%)`}
                                                                readOnly
                                                                onChange={() => { }}
                                                            />

                                                        </>
                                                    }
                                                />

                                            </div>

                                        )
                                    }

                                </SubContainer>

                            </>
                        )

                }

            </ModalCardContainer>

        </ShadowContainer>

    )
}
