import validator from 'validator';
import { useDispatch } from 'react-redux';
import { setError } from '../../actions/ui';
import { LinkButton } from '../../components/molecules/LinkButton';
import { useState } from 'react';
import { AuthTitleComponent } from '../../components/molecules/AuthTitleComponent';
import { startRegisterWithEmailPassword } from '../../actions/auth';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/molecules/Button';
import { Input } from '../../components/molecules/Input';

export const SignUpScreen = () => {

    const dispatch = useDispatch();

    const [values, setValues] = useState<{ fullName: string, email: string, password: string }>({
        fullName: '',
        email: '',
        password: '',
    })

    const history = useHistory()

    const { fullName, email, password } = values;

    const handleRegister = () => {

        if (isFormValid()) {

            dispatch(startRegisterWithEmailPassword(email, password, fullName, history))

        }

    }

    const isFormValid = () => {

        if (fullName.trim().length === 0) {
            dispatch(setError('Ingrese su nombre'));
            return false;
        } else if (!validator.isEmail(email)) {
            dispatch(setError('El email no es valido'));
            return false;
        } else if (password.length < 8) {
            dispatch(setError('La contraseña debe tener un mínimo de 8 caracteres'));
            return false;
        } else {

            return true;
        }

    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleRegister();
        }
    };

    return (

        <>

            <AuthTitleComponent
                text={`Comenzá a administrar tus tareas ya mismo`}
            />

            <Input
                type='text'
                label='Tu nombre y apellido'
                placeholder='Nombre y apellido'
                value={fullName}
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        fullName: e.target.value
                    })
                }}
            />

            <Input
                type='text'
                label='Tu correo electrónico'
                placeholder='Correo electrónico'
                value={email}
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        email: e.target.value.toLowerCase()
                    })
                }}
            />

            <Input
                type='password'
                label='Tu contraseña'
                placeholder='Contraseña'
                value={password}
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        password: e.target.value
                    })
                }}
            />

            <Button
                type='primary'
                text='Crear cuenta'
                fullWidth
                onClick={() => handleRegister()}
            />

            <LinkButton
                label='¿Ya tenes una cuenta?'
                text='Accedé ahora'
                link='/auth/iniciar-sesion'
            />


        </>

    )
}
