import { styled } from "styled-components"
import { device } from '../../styles/stylesConfig';
import { ScreenContainer } from '../../components/templates/ScreenContainer';
import { OptionButtons } from '../../components/organisms/OptionButtons';
import { ActionButtonsProps, ProductProps } from '../../interfaces/ProductInterfaces';
import { ProductBrandsHeader } from "../../components/organisms/ProductBrandsHeader";
import { useEffect, useRef, useState } from "react";
import { ProductsListView } from "../../components/organisms/ProductsListView";
import { UpdatePricesModal } from "../../components/organisms/UpdatePricesModal";
import { useDispatch, useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { LoadingComponent } from "../../components/molecules/LoadingComponent";
import { UpdateProfitModal } from "../../components/organisms/UpdateProfitModal";
import { NewProductModal } from "../../components/organisms/NewProductModal";
import { initialJsonProductProps } from '../../utils/Jsons';
import { ScanProductModal } from "../../components/organisms/ScanProductModal";
import { ProductDetailsModal } from "../../components/organisms/ProductDetailsModal";
import { showMixinToast, showModalMessage } from "../../utils/Alerts";
import { AssignCodeToProductModal } from "../../components/organisms/AssignCodeToProductModal";
import { UpdateProductPriceModal } from "../../components/organisms/UpdateProductPriceModal";
import { UpdateProductStockModal } from "../../components/organisms/UpdateProductStockModal";
import { SellProductModal } from "../../components/organisms/SellProductModal";
import { UpdateProductProfitManualModal } from "../../components/organisms/UpdateProductProfitManualModal";
import { handleDeleteProduct } from "../../helpers/ProductsHelper";

export const ButtonCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    position: relative;
    margin-top: 50px;

    & > h1 {
        position: absolute;
        top: -25px;
        left: 0px;

        @media ${device.md} {
        font-size: 16px !important;
        right: 0px;
        
        } 

        @media ${device.sm} {
            font-size: 14px !important;
        } 
    }

    @media ${device.xs} {
        flex-direction: column;
    }
`
export const HomeScreen = () => {

    const { products, checkingProducts, checkingBrands } = useSelector((state: StoreProps) => state.fallherramientas);

    const { role, token } = useSelector((state: StoreProps) => state.auth);

    const [searchProduct, setSearchProduct] = useState<string>('');

    const [newProductData, setNewProductData] = useState<ProductProps>(initialJsonProductProps);

    const [brandSelected, setBrandSelected] = useState<number | null>(null);

    const [scanProductModalVisible, setScanProductModalVisible] = useState<boolean>(false);

    const [productDetailsModalVisible, setProductDetailsModalVisible] = useState<ProductProps | null>(null);

    const [updateProductPriceModalVisible, setUpdateProductPriceModalVisible] = useState<ProductProps | null>(null);

    const [updateProductProfitManualModalVisible, setUpdateProductProfitManualModalVisible] = useState<ProductProps | null>(null);

    const [updateProductStockModalVisible, setUpdateProductStockModalVisible] = useState<ProductProps | null>(null);

    const [sellProductModalVisible, setSellProductModalVisible] = useState<ProductProps | null>(null);

    const [assignCodeToProductModalVisible, setAssignCodeToProductModalVisible] = useState<string | null>(null);

    const [newProductModalVisible, setNewProductModalVisible] = useState<boolean>(false);

    const [updateProfitModalVisible, setUpdateProfitModalVisible] = useState<boolean>(false);

    const [updatePricesModalVisible, setUpdatePricesModalVisible] = useState<boolean>(false);

    const dispatch = useDispatch();

    const actionButtonsData: ActionButtonsProps[] = [
        {
            id: 0,
            name: 'Buscar un producto',
            type: 'dark',
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') setScanProductModalVisible(true) }
        },
        {
            id: 1,
            name: 'Agregar nuevo producto',
            type: 'dark',
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') setNewProductModalVisible(true) }
        },
        {
            id: 2,
            name: `Modificar ganancia actual`,
            type: 'dark',
            show: role === 'Administrador',
            handleClick: () => { if (role === 'Administrador') setUpdateProfitModalVisible(true) }
        },
        {
            id: 3,
            name: 'Aumentar todos los costos',
            type: 'dark',
            show: true,
            handleClick: () => { setUpdatePricesModalVisible(true) }
        },

    ]

    const getFilteredProductsList = (list: ProductProps[], brandSelected: number | null, searchProduct: string) => {

        let filteredList = list

        if (brandSelected !== null) {
            filteredList = filteredList.filter((value) => { return value.brandId === brandSelected });
        }

        if (searchProduct.trim() !== '') {
            filteredList = filteredList.filter((value) => { return value.name.toLowerCase().includes(searchProduct.toLocaleLowerCase().trim()) || value.model.toLowerCase().includes(searchProduct.toLocaleLowerCase().trim()) });
        }

        return filteredList;

    }

    const [barcode, setBarcode] = useState('');

    const [tienePrefijo, setTienePrefijo] = useState(false);

    const [notFoundProduct, setNotFoundProduct] = useState<boolean>(false);

    const inputtRef: any = useRef(null);

    const findProduct = async (code: string) => {

        const productFounded = products.find((value) => { return value.barCode === code })

        if (productFounded) {

            showMixinToast('Producto encontrado', 'success')

            setProductDetailsModalVisible(null);

            setScanProductModalVisible(false);

            setProductDetailsModalVisible(productFounded);

            setNotFoundProduct(false)

            setAssignCodeToProductModalVisible(null);

        } else {

            showMixinToast('Producto no encontrado', 'warning')

            setProductDetailsModalVisible(null);

            setNotFoundProduct(true)

            setScanProductModalVisible(true)

            setAssignCodeToProductModalVisible(null);

            setTimeout(() => {

                setBarcode(code);

            }, 300);

        }

    }

    const handleChange = (event: any) => {

        if (/^[a-zA-Z0-9%]*$/.test(event.target.value)) {

            setBarcode(event.target.value);

            setNotFoundProduct(false)

            if (event.target.value.startsWith('%A%')) {
                setTienePrefijo(true);
            } else {
                setTienePrefijo(false);
            }

        }

    };

    useEffect(() => {

        const timerId = setTimeout(() => {

            if (barcode.includes('%A%') && barcode.includes('%Z%')) {

                const regex = /%A%(.*?)%Z%/;
                const match = barcode.match(regex);

                if (match && match.length > 1) {
                    // setCode(match[1].replace('%A%', '').replace('%Z%', ''));
                    setBarcode(match[1].replace('%A%', '').replace('%Z%', ''));
                    findProduct(match[1].replace('%A%', '').replace('%Z%', ''));
                }

                setBarcode('')
            }
            setBarcode('')

        }, 200);

        return () => clearTimeout(timerId);
    }, [barcode]);



    useEffect(() => {

        const handleKeyDown = (event: any) => {
            if (/^[%]$/i.test(event.key) && !scanProductModalVisible && !newProductModalVisible && role === 'Administrador') {
                inputtRef.current!.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [tienePrefijo, newProductModalVisible, role, scanProductModalVisible]);

    return (

        <ScreenContainer
            style={{
                display: newProductModalVisible
                    || scanProductModalVisible
                    || updateProductPriceModalVisible
                    || productDetailsModalVisible
                    || updateProductProfitManualModalVisible
                    || updateProductStockModalVisible
                    || sellProductModalVisible
                    || assignCodeToProductModalVisible
                    || updateProfitModalVisible
                    || updatePricesModalVisible
                    ? 'unset' : 'flex'
            }}
        >

            {
                role === 'Administrador' && (

                    <input
                        ref={inputtRef}
                        type="text"
                        value={barcode}
                        onChange={handleChange}
                        placeholder="Escanea el código de barras y presiona Enter"
                        style={{ height: 0, width: 0, padding: 0, outline: 'none', border: 'none', position: 'absolute', top: -1000, left: -1000 }}
                    />

                )
            }

            {
                checkingProducts || checkingBrands ? (
                    <LoadingComponent />
                ) : (

                    <>

                        <OptionButtons data={actionButtonsData.filter((value) => { return value.show === true })} />

                        <ProductBrandsHeader brandSelected={brandSelected} setBrandSelected={setBrandSelected} searchProduct={searchProduct} setSearchProduct={setSearchProduct} />

                        {
                            role === 'Administrador' && scanProductModalVisible &&
                            <ScanProductModal
                                setVisible={setScanProductModalVisible}
                                setData={setProductDetailsModalVisible}
                                notFoundProduct={notFoundProduct}
                                setNotFoundProduct={setNotFoundProduct}
                                code={barcode}
                                setCode={setBarcode}
                                onScan={() => {
                                    setAssignCodeToProductModalVisible(null)
                                }}
                                onSetAssignCodeToProduct={(c: string) => {

                                    setAssignCodeToProductModalVisible(c);

                                }}
                                onSetCodeToNewProduct={(c: string) => {

                                    setNewProductData({ ...initialJsonProductProps, barCode: c });

                                    setNewProductModalVisible(true);
                                }}
                            />}

                        {productDetailsModalVisible !== null &&
                            <ProductDetailsModal
                                data={productDetailsModalVisible}
                                setData={setProductDetailsModalVisible}
                                onDeleteProduct={(product: ProductProps) => {

                                    handleDeleteProduct(product, dispatch, token, setProductDetailsModalVisible);

                                }}
                                onModifyProduct={(product: ProductProps) => {

                                    setNewProductData(product);

                                    setNewProductModalVisible(true);

                                }}
                                onUpdatePriceProduct={(product: ProductProps) => {

                                    setUpdateProductPriceModalVisible(product);

                                }}
                                onUpdateProfitManualProduct={(product: ProductProps) => {

                                    setUpdateProductProfitManualModalVisible(product);

                                }}
                                onUpdateStockProduct={(product: ProductProps) => {

                                    setUpdateProductStockModalVisible(product);

                                }}
                                onSellProduct={(product: ProductProps) => {

                                    if (product.stockAmount <= 0) {

                                        showModalMessage('Ups', `No hay suficiente stock para vender`, 'warning');

                                    } else {

                                        setSellProductModalVisible(product);

                                    }

                                }}
                            />
                        }

                        {role === 'Administrador' && newProductModalVisible &&
                            <NewProductModal
                                data={newProductData}
                                setData={setNewProductData}
                                setVisible={setNewProductModalVisible}
                                setScanProductModalVisible={setScanProductModalVisible}
                                onConfirm={(product: ProductProps) => {

                                    if (productDetailsModalVisible !== null) {

                                        setProductDetailsModalVisible(null);

                                        setProductDetailsModalVisible(product);

                                    }

                                    setNotFoundProduct(false)

                                }}
                            />
                        }

                        {updateProductPriceModalVisible !== null &&
                            <UpdateProductPriceModal
                                data={updateProductPriceModalVisible}
                                setData={setUpdateProductPriceModalVisible}
                                onConfirm={(product: ProductProps) => {

                                    if (productDetailsModalVisible !== null) {

                                        setProductDetailsModalVisible(null);

                                        setProductDetailsModalVisible(product);

                                    }

                                }}
                            />
                        }

                        {role === 'Administrador' && updateProductProfitManualModalVisible !== null &&
                            <UpdateProductProfitManualModal
                                data={updateProductProfitManualModalVisible}
                                setData={setUpdateProductProfitManualModalVisible}
                                onConfirm={(product: ProductProps) => {

                                    if (productDetailsModalVisible !== null) {

                                        setProductDetailsModalVisible(null);

                                        setProductDetailsModalVisible(product);

                                    }

                                }}
                            />
                        }

                        {role === 'Administrador' && updateProductStockModalVisible !== null &&
                            <UpdateProductStockModal
                                data={updateProductStockModalVisible}
                                setData={setUpdateProductStockModalVisible}
                                onConfirm={(product: ProductProps) => {

                                    if (productDetailsModalVisible !== null) {

                                        setProductDetailsModalVisible(null);

                                        setProductDetailsModalVisible(product);

                                    }

                                }}
                            />
                        }

                        {role === 'Administrador' && sellProductModalVisible !== null &&
                            <SellProductModal
                                data={sellProductModalVisible}
                                setData={setSellProductModalVisible}
                                onConfirm={(product: ProductProps) => {

                                    if (productDetailsModalVisible !== null) {

                                        setProductDetailsModalVisible(null);

                                        setProductDetailsModalVisible(product);

                                    }

                                }}
                            />
                        }

                        {
                            assignCodeToProductModalVisible !== null &&
                            <AssignCodeToProductModal
                                data={assignCodeToProductModalVisible}
                                setData={setAssignCodeToProductModalVisible}
                                onAssingCodeToExistingProduct={() => {

                                    setScanProductModalVisible(false);

                                    setNotFoundProduct(false)
                                }}
                            />
                        }

                        {role === 'Administrador' && updateProfitModalVisible && <UpdateProfitModal setVisible={setUpdateProfitModalVisible} />}

                        {updatePricesModalVisible && <UpdatePricesModal setVisible={setUpdatePricesModalVisible} />}

                        <ProductsListView
                            productSearched={searchProduct}
                            data={getFilteredProductsList(products, brandSelected, searchProduct)}
                            onClickProduct={(product: ProductProps) => {

                                setProductDetailsModalVisible(product);

                            }}
                        />

                    </>

                )
            }

        </ScreenContainer>

    )
}