import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useEffect, useState } from "react";
import { showMixinToast, showModalConfirm } from "../../utils/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../molecules/Input";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { startLoading } from "../../actions/ui";
import { startUpdateGlobalProfitValueQuery } from '../../queries/ProductQueries';

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 45%;
    max-width: 90%;
    max-height: 75%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 75%;
    } 


    @media ${device.md} {
        width: 95%;
        max-width: 95%;
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 21px;
        }

    }
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    & > h1 {
        width: 100%;
        text-align: left;
        white-space: normal;
        color: ${colors.grey_dark};
        margin-top: 10px;
        border-bottom: 1px solid ${colors.grey_normal};
        padding-bottom: 10px;
    }
`

const TextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > h1 {
        width: 100%;
        text-align: center;
        white-space: normal;
    }
    
`

interface Props {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateProfitModal = ({ setVisible }: Props) => {

    const { profit, checkingProfit } = useSelector((state: StoreProps) => state.fallherramientas);

    const { token } = useSelector((state: StoreProps) => state.auth);

    const [profitForm, setProfitForm] = useState<number>(profit ?? 0);

    useEffect(() => {

        if (profit)
            setProfitForm(profit)

    }, [profit, checkingProfit])

    const [fieldIncomplete, setFieldIncomplete] = useState({
        profit: false,
    })

    const dispatch = useDispatch()

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            profit: profitForm === profit,
        });

        if (profitForm === profit) {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            showModalConfirm({
                title: '¿Estas seguro?',
                description: `Estas a punto de indicar que la ganancia va a pasar de ${profit}% a ${profitForm}%.`,
                icon: 'warning',
                confirmButtonText: 'Si, estoy seguro',
                handleConfirm: async () => {

                    dispatch(startLoading())

                    const body = {
                        profit: profitForm
                    }

                    await startUpdateGlobalProfitValueQuery(body, token!, dispatch);

                    showMixinToast('Ganancia actualizada', 'success');

                    setVisible(false);

                }
            })

        }

    }

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Actualizar ganancia
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(false) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <TextContainer>

                    <Typography size='16px' color={colors.grey_dark}>
                        Su ganancia actual a todos los productos es de:
                    </Typography>

                    <Typography size='42px' bold color={colors.primary} style={{ margin: '15px 0px', border: `2px solid ${colors.primary_light}`, padding: 10, borderRadius: 5 }}>
                        {profit}%
                    </Typography>

                    <Typography size='16px' color={colors.grey_dark} style={{ textAlign: 'left' }}>
                        Se aplicará el porcentaje de ganancia que establezcas acá a todos los productos que indiques como vendidos.
                    </Typography>

                </TextContainer>

                <ContentContainer>

                    <Typography size='16px' bold style={{ marginTop: 30 }}>
                        Indique el nuevo porcentaje de ganancia:
                    </Typography>

                    <Input
                        label="Nuevo porcentaje de ganancia"
                        type="select"
                        value={profitForm}
                        onChange={(e) => {

                            setProfitForm(parseInt(e.target.value));
                        }}
                    >

                        {
                            [...Array(101)].map((x, i) => (

                                <option key={x} value={i.toString()}>{i}%{profit === i ? ' (ganancia actual)' : ''}</option>

                            ))
                        }

                        <option value={'0'}>0%{profit === 0 ? ' (ganancia actual)' : ''}</option>
                        <option value={'5'}>5%{profit === 5 ? ' (ganancia actual)' : ''}</option>
                        <option value={'10'}>10%{profit === 10 ? ' (ganancia actual)' : ''}</option>
                        <option value={'15'}>15%{profit === 15 ? ' (ganancia actual)' : ''}</option>
                        <option value={'20'}>20%{profit === 20 ? ' (ganancia actual)' : ''}</option>
                        <option value={'25'}>25%{profit === 25 ? ' (ganancia actual)' : ''}</option>
                        <option value={'30'}>30%{profit === 30 ? ' (ganancia actual)' : ''}</option>
                        <option value={'35'}>35%{profit === 35 ? ' (ganancia actual)' : ''}</option>
                        <option value={'40'}>40%{profit === 40 ? ' (ganancia actual)' : ''}</option>
                        <option value={'45'}>45%{profit === 45 ? ' (ganancia actual)' : ''}</option>
                        <option value={'50'}>50%{profit === 50 ? ' (ganancia actual)' : ''}</option>
                        <option value={'55'}>55%{profit === 55 ? ' (ganancia actual)' : ''}</option>
                        <option value={'60'}>60%{profit === 60 ? ' (ganancia actual)' : ''}</option>
                        <option value={'65'}>65%{profit === 65 ? ' (ganancia actual)' : ''}</option>
                        <option value={'70'}>70%{profit === 70 ? ' (ganancia actual)' : ''}</option>
                        <option value={'75'}>75%{profit === 75 ? ' (ganancia actual)' : ''}</option>
                        <option value={'80'}>80%{profit === 80 ? ' (ganancia actual)' : ''}</option>
                        <option value={'85'}>85%{profit === 85 ? ' (ganancia actual)' : ''}</option>
                        <option value={'90'}>90%{profit === 90 ? ' (ganancia actual)' : ''}</option>
                        <option value={'95'}>95%{profit === 95 ? ' (ganancia actual)' : ''}</option>
                        <option value={'100'}>100%{profit === 100 ? ' (ganancia actual)' : ''}</option>
                    </Input>

                </ContentContainer>

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={profitForm === profit || checkingProfit ? 'Indique el porcentaje de ganancia' : 'Actualizar porcentaje de ganancia'}
                    secondaryButtonText='Cerrar'
                    primaryDisabled={profitForm === profit || checkingProfit}
                    secondaryDisabled={false}
                    onClickPrimary={() => {

                        // Funcion a back para actualizar

                        handleFormConfirm()


                    }}
                    onClickSecondary={() => {
                        setVisible(false);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
