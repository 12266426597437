import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { ProductProps } from "../../interfaces/ProductInterfaces";
import { Icon } from "../atoms/Icon";

const ButtonCard = styled.button`
    background-color: white;
    border-radius: 5px;
    width: 300px;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
    border: ${colors.primary_light} 1px solid;
    box-shadow: 0px 0px 12px #00000050;
    transition: all 0.1s ease;
    align-self: center;
    justify-self: center;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 3px 12px #00000050;
        transform: scale(1.01);
    }

    @media ${device.xl} {
        width: 260px;
        height: 290px;
    }

    @media ${device.sm} {
        width: 170px;
        height: 220px;
        padding: 15px;

    }

    @media ${device.xs} {
        width: 130px;
        height: 170px;
        padding: 10px;
    }

    hr {
        border: none;

        @media ${device.xs} {
            position: absolute;
            visibility: hidden;
        }
    }

    img {
        width: 180px !important; 
        height: 180px !important; 
        object-fit: contain !important; 
        object-position: center !important;

        @media ${device.xl} {
            width: 150px !important; 
            height: 150px !important; 
        }

        @media ${device.sm} {
            width: 100px !important; 
            height: 100px !important; 
        }

        @media ${device.xs} {
            width: 80px !important; 
            height: 80px !important; 
        }
    }
`


const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.sm} {

        h1:first-child {
            font-size: 12px;
        }
        h1:last-child {
            font-size: 12px;
        }
    }

    @media ${device.xs} {

        h1:first-child {
            font-size: 10px;
        }
        h1:last-child {
            font-size: 10px;
        }
    }

`

interface Props {
    data: ProductProps;
    onClick: () => void
}

export const ProductCardItem = ({ data, onClick }: Props) => {

    return (

        <ButtonCard onClick={() => { onClick() }}>

            <hr />

            <Icon
                size="XXL"
                src={data.image ?? require('../../images/boy.jpg')}
            />

            <Info>

                <Typography
                    size='16px'
                    bold
                    style={{
                        textTransform: 'uppercase',
                    }}
                >
                    {data.name}
                </Typography>

                <Typography
                    size='14px'
                    style={{
                        marginTop: 5,
                    }}
                >
                    {data.model}
                </Typography>

            </Info>

        </ButtonCard>

    )
}
