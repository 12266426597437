import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "./Button";
import { showMixinToast, showModalConfirm } from "../../utils/Alerts";
import { LoadingComponent } from "./LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { ProductProps } from "../../interfaces/ProductInterfaces";
import { finishLoading, setError, startLoading } from "../../actions/ui";
import axios from "axios";
import { endpoints } from "../../helpers/FirebaseHelper";
import { formatDateHourText, formatPrice } from "../../utils/Utilities";
import { login } from "../../actions/auth";
import { getAuth } from "firebase/auth";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { DeleteSoldProps, SoldProps } from "../../interfaces/SoldInterfaces";
import { startDeleteSoldQuery } from "../../queries/SoldQueries";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10px;

    & > h1 {
        width: 100%;    
        text-align: start;
        margin-bottom: 10px;
        margin-left: 15px;

        @media ${device.sm} {
        text-align: center;
        margin-left: 0px;
    }
    }
`

const GroupContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: ${colors.light};
    border-radius: 5px;
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid ${colors.grey_normal};

`

const ItemsContainer = styled.div`
    min-width: 100%;
    display: grid;
    grid-template-columns: repeat(6, minmax(200px, 1fr));
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.grey_normal};

    &:first-of-type {
        padding: 0px;
    }

    &:last-of-type {
        border-bottom: 1px solid transparent;
    }
`

const BoxData = styled.div`
    width: 100%;
    height: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > button,
    & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
    }

    & > button {
        margin-right: 10px;
    }

    & > button:last-of-type {
        margin-right: 0px;
    }
`

interface Props {
    data: SoldProps[];
    setProductDetailsModal: React.Dispatch<React.SetStateAction<ProductProps | null>>;
    setSoldProductModalVisible: React.Dispatch<React.SetStateAction<SoldProps | null>>;
}

export const SoldList = ({ data, setProductDetailsModal, setSoldProductModalVisible }: Props) => {

    const { checkingSold, products, checkingProducts } = useSelector((state: StoreProps) => state.fallherramientas);

    const { token } = useSelector((state: StoreProps) => state.auth);

    const dispatch = useDispatch();

    return (

        <MainContainer>

            <GroupContainer>

                {
                    !checkingSold || !checkingProducts ? (

                        <>

                            <ItemsContainer>

                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Fecha</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Producto</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Precio unitario</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Cantidad</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Ganancia</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Acciones</Typography></BoxData>

                            </ItemsContainer>

                            {
                                data.length === 0 ? (

                                    <Typography size='16px' style={{ marginTop: 20, marginBottom: 15, textAlign: 'center', width: '100%' }}>No existen productos vendidos en este mes</Typography>

                                ) : (

                                    data.map((value, index) => {

                                        const { id, productId, amount, date, unitPrice, profit, } = value

                                        const product = products.find((value) => { return value.id === productId });

                                        return (

                                            <ItemsContainer key={id} style={{ backgroundColor: index % 2 !== 0 ? colors.grey_normal : undefined }}>

                                                <BoxData><Typography size='14px'>{formatDateHourText(date)}</Typography></BoxData>
                                                <BoxData>
                                                    {
                                                        product ?
                                                            (<div><Button type="terciary" text={`${product.name.substring(0, 17)}...`} onClick={() => { setProductDetailsModal(product); }} /></div>)
                                                            :
                                                            (<Typography size='14px'>Cargando...</Typography>)
                                                    }
                                                </BoxData>
                                                <BoxData><Typography size='14px'>${formatPrice(unitPrice)}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{amount}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>${formatPrice(((unitPrice * (profit / 100)) * amount))} <strong>({formatPrice(profit)}%)</strong></Typography></BoxData>
                                                <BoxData>

                                                    <Button
                                                        type="action"
                                                        icon={require('../../images/edit.png')}
                                                        onClick={() => {

                                                            if (product)
                                                                setSoldProductModalVisible(value);

                                                        }}
                                                    />

                                                    <Button
                                                        type="action"
                                                        icon={require('../../images/delete-primary-dark.png')}
                                                        onClick={() => {

                                                            const handleDelete = async () => {

                                                                dispatch(startLoading())

                                                                const body: DeleteSoldProps = {
                                                                    id
                                                                }

                                                                await startDeleteSoldQuery(body, token!, dispatch);

                                                                showMixinToast('Producto eliminado', 'success')

                                                                dispatch(finishLoading())

                                                            }

                                                            showModalConfirm({
                                                                title: '¿Estas seguro?',
                                                                // description: `Estas a punto de eliminar la venta del producto [${product?.name.toUpperCase()}].`, // SI NO REESTOCKEA
                                                                description: `
                                                                Estas a punto de eliminar la venta y reingresar 
                                                                ${amount === 1 ? amount + ' UNIDAD' : amount + ' UNIDADES'}
                                                                del producto [${product?.name.toUpperCase()}] al stock.
                                                                `, // SI REESTOCKEA
                                                                icon: 'warning',
                                                                confirmButtonText: 'Si, estoy seguro',
                                                                handleConfirm: () => { handleDelete() }
                                                            })


                                                        }}
                                                    />

                                                </BoxData>

                                            </ItemsContainer>
                                        )
                                    })

                                )

                            }

                        </>

                    ) :
                        (
                            <LoadingComponent />
                        )
                }





            </GroupContainer>


        </MainContainer>

    )
}