import {
    getAuth,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth';
import { types } from '../types/types';
import { finishLoading, setError, startLoading } from './ui';
import Swal from 'sweetalert2'
import { AuthReducerProps } from '../interfaces/ReducerInterfaces';
import { formatExpirationTime } from '../utils/Utilities';
import { startCreateUserQuery } from '../queries/AuthQueries';

// REGISTRARSE CON EMAIL Y CONTRASEÑA //

export const startRegisterWithEmailPassword = (email: any, password: any, name: any, history: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        const body = { email, password, passwordConfirm: password, fullname: name };

        await startCreateUserQuery(body, dispatch, history);

        dispatch(finishLoading())

    }

}

// INGRESAR CON EMAIL Y CONTRASEÑA //

export const startLoginWithEmailPassword = (email: any, password: any, history: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        const auth = getAuth();

        await signInWithEmailAndPassword(auth, email, password)
            .then(async ({ user }) => {

                const tokenResult = await user.getIdTokenResult(true)

                dispatch(login({
                    uid: user.uid,
                    name: user.displayName,
                    token: tokenResult.token,
                    role: tokenResult.claims.role,
                    expirationTime: formatExpirationTime(tokenResult.expirationTime)
                }))

                dispatch(finishLoading())

                await Swal.fire('¡Excelente!', '¡Bienvenido/a nuevamente, ' + user.displayName + "!", 'success')

            })
            .catch(e => {

                dispatch(finishLoading())

                let msg = ''

                if (e.message.includes('auth/user-not-found')) {
                    msg = 'El email no se encuentra registrado.'
                } else if (e.message.includes('auth/wrong-password')) {
                    msg = 'La contraseña ingresada es incorrecta.'
                } else {
                    msg = 'Ha ocurrido un error'
                }

                if (e.message.includes('auth/user-disabled')) {
                    history.push('/auth/estado')
                } else {
                    dispatch(setError(msg))

                }
            })

    }

}

// FUNCION LOGIN QUE CREA EL OBJETO AUTH DEL STORE //

export const login = ({ uid, name, token, role, expirationTime }: AuthReducerProps) => (
    {
        type: types.login,
        payload: {
            uid,
            name,
            token,
            role,
            expirationTime
        }
    }
)

// CERRAR SESION //

export const startLogout = () => {

    return async (dispatch: any) => {

        const auth = getAuth();

        await signOut(auth);

        dispatch(logout());


    }
}

// FUNCION LOGOUT PARA BORRAR EL USER //

export const logout = () => ({
    type: types.logout,
})

// ENVIAR MAIL DE VERIFICACION DE CORREO //

export const startSendEmailVerification = (email: any, password: any, seller: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        const auth = getAuth();

        const user = auth.currentUser;

        if (user) {

            await sendEmailVerification(user, { url: 'https://fall-stock.netlify.app/auth/iniciar-sesion' })
                .then(async () => {

                    dispatch(finishLoading())

                    await Swal.fire('¡Atención!', 'Le hemos enviado un mail para verificar su cuenta. Por favor revise su correo electrónico.', 'info');

                }).catch(async e => {

                    dispatch(finishLoading())

                    console.log(e);

                    let msg = ''

                    if (e.message.includes('auth/user-not-found')) {
                        msg = 'El email no se encuentra registrado.'
                    } else if (e.message.includes('auth/wrong-password')) {
                        msg = 'La contraseña ingresada es incorrecta.'
                    } else {
                        msg = 'Ha ocurrido un error'
                    }

                    dispatch(setError(msg));
                })

        } else {

            dispatch(setError('No se encuentra autenticado.'));

        }

    }

}

// ENVIAR MAIL PARA CAMBIAR CONTRASEÑA //

export const startSendPasswordResetEmail = (email: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        const auth = getAuth();

        await sendPasswordResetEmail(auth, email, { url: 'https://fall-stock.netlify.app/auth/iniciar-sesion' })
            .then(() => {

                Swal.fire('¡Atención!', 'Le hemos enviado un mail para que modifique su contraseña. Por favor revise su correo electrónico.', 'info')

                dispatch(finishLoading())

            }).catch(e => {
                console.log(e);
                dispatch(finishLoading())

                let msg = ''

                if (e.message.includes('auth/invalid-email')) {
                    msg = 'El email no es correcto.'
                } else if (e.message.includes('auth/user-not-found')) {
                    msg = 'Usuario no encontrado.'
                } else {
                    msg = 'Ha ocurrido un error'
                }

                dispatch(setError(msg));


            })

    }

}
