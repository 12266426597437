import { styled } from "styled-components";
import { AccessProps } from "../../interfaces/AuthInterfaces";
import { MemberCardItem } from "./MemberCardItem";
import { device } from "../../styles/stylesConfig";

const MainContainer = styled.div`
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 30px;

    @media ${device.lg} {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

    }

    @media ${device.md} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr);
    }
`


interface Props {
    data: AccessProps[];
    setAccessRoleModal: React.Dispatch<React.SetStateAction<AccessProps | null>>
}

export const MembersListView = ({ data, setAccessRoleModal }: Props) => {

    return (

        <MainContainer>

            {
                data.map((value) => {

                    return (
                        <MemberCardItem key={value.id} data={value} onClick={() => { setAccessRoleModal(value) }} />
                    )
                })
            }

        </MainContainer>

    )
}