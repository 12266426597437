import styled from "styled-components"
import { device } from "../../styles/stylesConfig"

const ContentContainer = styled.div`
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    z-index: 2;
`

const Title = styled.div`
    color: #2C3357;
    font-weight: 600;
    font-size: 30px;    

    @media ${device.sm} {
        font-size: 24px;
    }
`

interface Props {
    text: string
}

export const AuthTitleComponent = ({ text }: Props) => {

    return (

        <ContentContainer>

            <Title className='auth__tittle'>{text}</Title>

        </ContentContainer>


    )
}
