import axios from "axios"
import { finishLoading, setError } from "../actions/ui"
import Swal from "sweetalert2"
import { login } from "../actions/auth"
import { AuthReducerProps } from "../interfaces/ReducerInterfaces"
import { saveFallHerramientasMembers } from "../actions/fallHerramientas"
import { endpoints } from "../helpers/FirebaseHelper"
import { DeleteMemberProps, NewUserProps, SetRoleProps, UpdateFullnameProps } from "../interfaces/AuthInterfaces"

export const startCreateUserQuery = async (body: NewUserProps, dispatch: any, history: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createUser}`, body)
        .then((value) => {

            dispatch(finishLoading())

            Swal.fire('¡Bienvenido/a, ' + value.data.body.user.displayName + "!", "Le hemos solicitado acceso al administrador. Cuando te permita ingresar vas a poder gestionar tus tareas sin limite.", 'success')

            history.push('/auth/estado')

        }).catch((e) => {

            console.log(e.response.data.trace);
            console.log(e.response.data.message);

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {

                    if (e.response.data.trace.includes('auth/email-already-in-use') || e.response.data.trace.includes('Error: The email address is already in use by another account')) {
                        msg = 'El email ya se encuentra registrado.'
                    } else {
                        msg = e.response.data.message
                    }

                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const startUpdateFullnameQuery = async (body: UpdateFullnameProps, token: string, dispatch: any, onLoginDispatch: AuthReducerProps) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.updateFullname}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async (value) => {

            dispatch(finishLoading())

            dispatch(login(onLoginDispatch))

            Swal.fire('¡Listo!', value.data.message, 'success');


        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const startGetMembersQuery = async (token: string, dispatch: any) => {

    await axios.get(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.getMembers}`,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async (value) => {

            dispatch(saveFallHerramientasMembers(value.data.body))


        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const starDeleteMemberQuery = async (body: DeleteMemberProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.deleteMember}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async (value) => {

            await startGetMembersQuery(token, dispatch);

            dispatch(finishLoading())

            Swal.fire('¡Listo!', value.data.message, 'success');


        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const starApproveUpdateAccessQuery = async (body: SetRoleProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.approveAccess}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async (value) => {

            await startGetMembersQuery(token, dispatch);

            dispatch(finishLoading())

            Swal.fire('¡Listo!', value.data.message, 'success');

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}