import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";
import { finishLoading, setError } from "../actions/ui";
import { DeleteSoldProps, MarkUpdateAsSoldProps } from "../interfaces/SoldInterfaces";

export const startMarkAndUpdateProductAsSoldQuery = async (body: MarkUpdateAsSoldProps, token: string, dispatch: any) => {
    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.markUpdateSold}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async () => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })
}

export const startDeleteSoldQuery = async (body: DeleteSoldProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.deleteSold}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async () => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}