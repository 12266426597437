import { css, styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { CSSProperties, ReactNode } from "react";
import { FooterButtonsContainer } from "./FooterButtonsContainer";

interface Props {
    primaryButtonText?: string;
    secondaryButtonText?: string;
    terciaryButtonText?: string;
    primaryDisabled?: boolean;
    secondaryDisabled?: boolean;
    terciaryDisabled?: boolean;
    onClickPrimary?: () => void;
    onClickSecondary?: () => void;
    onClickTerciary?: () => void;
    style?: CSSProperties
    children?: ReactNode
}

const Card = styled.div<{ hasbutton: string }>`
background-color: ${colors.dark};
border-radius: 8px;
width: 100%;
height: fit-content;
display: flex;
flex-direction: column;
justify-content: center;
padding: 20px;
margin-top: 30px;
margin-bottom: 20px;
position: relative;
border: 1px solid ${colors.dark};
box-shadow: 0px 3px 6px #00000020;

${props => props.hasbutton === 'true' && css`
    margin-bottom: 60px;

    @media ${device.sm} {
        margin-bottom: 120px;
    }
    
    & > div:nth-child(3) {
        position: absolute;
        bottom: -60px;
        right: 0px;

        @media ${device.sm} {
            bottom: -120px;
            right: 0px;
        }
    }
`}



`

export const BoxContainer = ({
    style,
    children,
    primaryButtonText,
    secondaryButtonText,
    terciaryButtonText,
    primaryDisabled,
    secondaryDisabled,
    terciaryDisabled,
    onClickPrimary,
    onClickSecondary,
    onClickTerciary
}: Props) => {

    return (

        <Card style={style} hasbutton={(primaryButtonText || secondaryButtonText || terciaryButtonText) ? 'true' : 'false'}>

            {children}

            <FooterButtonsContainer
                primaryButtonText={primaryButtonText}
                secondaryButtonText={secondaryButtonText}
                terciaryButtonText={terciaryButtonText}
                primaryDisabled={primaryDisabled}
                secondaryDisabled={secondaryDisabled}
                terciaryDisabled={terciaryDisabled}
                onClickPrimary={onClickPrimary}
                onClickSecondary={onClickSecondary}
                onClickTerciary={onClickTerciary}
            />

        </Card>

    )
}
