import { styled } from "styled-components";
import { Typography } from "../atoms/Typography";
import { ProductProps } from "../../interfaces/ProductInterfaces";
import { colors, device } from "../../styles/stylesConfig";
import { StatisticItem } from "./StatisticItem";
import { SoldList } from "./SoldList";
import { CollapsableSection } from "./CollapsableSection";
import { getProfitMonth, getProfitVsPreviousMonth } from "../../helpers/StatisticsHelper";
import { useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { SoldProps } from "../../interfaces/SoldInterfaces";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    & > h1 {

        @media ${device.sm} {
            font-size: 20px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }
    }
`

const ItemsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    @media ${device.sm} {
        flex-direction: column;
    }

`

interface Props {
    data: SoldProps[];
    defaultExpanded?: boolean;
    setProductDetailsModal: React.Dispatch<React.SetStateAction<ProductProps | null>>;
    setSoldProductModalVisible: React.Dispatch<React.SetStateAction<SoldProps | null>>;
}

export const StatisticsSection = ({ data, defaultExpanded, setProductDetailsModal, setSoldProductModalVisible }: Props) => {

    const { sold } = useSelector((state: StoreProps) => state.fallherramientas);

    return (

        <MainContainer>

            <ItemsDiv>

                <StatisticItem
                    type="black"
                    title="Productos vendidos"
                    data={data.length}
                />

                <StatisticItem
                    type="black"
                    title="Ganancia obtenida"
                    data={getProfitMonth(data)}
                />

                <StatisticItem
                    type="black"
                    title={data.length === 0 ? `Comparación mes anterior` : `Comparación mes anterior ${getProfitVsPreviousMonth(sold, data[0].date.toString().substring(0, 6)).previousMonth}`}
                    data={data.length === 0 ? '-' : getProfitVsPreviousMonth(sold, data[0].date.toString().substring(0, 6)).percentage}
                />

            </ItemsDiv>

            <CollapsableSection
                defaultExpanded={defaultExpanded}
                headerElement={
                    <Typography
                        size="18px"
                        bold
                    >
                        Productos vendidos ({data.length})
                    </Typography>
                }
                containerStyle={{ marginTop: 30, backgroundColor: colors.light, border: `1px  solid ${colors.grey_normal}` }}
                bodyElement={
                    <SoldList data={data} setProductDetailsModal={setProductDetailsModal} setSoldProductModalVisible={setSoldProductModalVisible} />
                }

            />


        </MainContainer>

    )
}