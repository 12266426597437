import { styled } from "styled-components";
import { Typography } from "../atoms/Typography";
import { colors, device } from "../../styles/stylesConfig";

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px 20px;
    border: 1px solid ${colors.dark};
    border-radius: 5px;
    margin: 0 5px;
    background-color: ${colors.primary};

    @media ${device.lg} {

        & > h1:first-of-type {
            font-size: 15px;
        }

        & > h1:last-of-type {
            font-size: 20px;
        }
    }

    @media ${device.sm} {
        width: 100%;
        margin: 0;
        border-radius: 0;
        border: 0px solid transparent;
        border-bottom: 1px solid transparent;
        background-color: transparent !important;
        border-color: ${colors.grey_normal} !important;

        & > h1 {
            color: ${colors.dark}
        }
    }
`

interface Props {
    type: 'white' | 'black' | 'black&white'
    title: string;
    data: number | string;
}

export const StatisticItem = ({ type, title, data }: Props) => {

    return (

        <MainContainer>

            <Typography
                size="20px"
                color={colors.dark}
                style={{ marginBottom: 15, textTransform: 'uppercase', textAlign: 'center' }}
            >
                {title}
            </Typography>

            <Typography
                size="32px"
                bold
                color={colors.dark}
            >
                {data}
            </Typography>

        </MainContainer>

    )
}