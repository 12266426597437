import { CSSProperties } from "react"
import { Icon } from "../atoms/Icon";

interface Props {
    style?: CSSProperties;
}

export const Logo = ({ style }: Props) => {

    return (

        <Icon
            size="XXL"
            style={{ ...style }}
            src={require('../../images/boy.jpg')}
        />

    )
}
