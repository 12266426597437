import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useEffect, useState } from "react";
import { showMixinToast, showModalConfirm, showModalMessage } from "../../utils/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../molecules/Input";
import { formatPrice } from "../../utils/Utilities";
import { UpadateTypes, UpdatePricesProps } from "../../interfaces/PricesInterfaces";
import { Checkbox } from "../molecules/Checkbox";
import { BrandProps } from "../../interfaces/ProductInterfaces";
import { LoadingComponent } from "../molecules/LoadingComponent";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { startUpdateAllProductPricesQuery } from "../../queries/ProductQueries";
import { startLoading } from "../../actions/ui";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 45%;
    max-width: 90%;
    max-height: 75%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 75%;
    } 


    @media ${device.md} {
        width: 95%;
        max-width: 95%;
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 21px;
        }

    }
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > h1 {
        width: 100%;
        text-align: left;
        white-space: normal;
        color: ${colors.grey_dark};
        margin-top: 10px;
        border-bottom: 1px solid ${colors.grey_normal};
        padding-bottom: 10px;
    }
`

const UpdateContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;

    @media ${device.sm} {
        flex-direction: column;
    } 

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.sm} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }
    
`

interface Props {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdatePricesModal = ({ setVisible }: Props) => {

    const { token } = useSelector((state: StoreProps) => state.auth);

    const { brands, checkingBrands } = useSelector((state: StoreProps) => state.fallherramientas);

    const [form, setForm] = useState<UpdatePricesProps>({
        brandsSelected: [],
        updateType: 'Aumentar precios',
        percentage: 0
    });

    const [fieldIncomplete, setFieldIncomplete] = useState({
        percentage: false,
    })

    const dispatch = useDispatch()

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            percentage: Number.isNaN(form.percentage) || form.percentage <= 0,
        });

        if (form.percentage <= 0 || Number.isNaN(form.percentage)) {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (Number.isNaN(form.percentage) || form.percentage <= 0) {

            showModalMessage(
                'Ups...',
                `Tenes que indicar un porcentaje.`,
                'warning'
            )

        } else {


            if (!formValidations()) {

                const brandsSelectedText = form.brandsSelected.length === 0 ? 'todas las marcas.'
                    : form.brandsSelected.map((brand) => { return brands.filter((value: BrandProps) => brand === value.id)[0].name }).map(brand => brand).join(', ')


                showModalConfirm({
                    title: '¿Estas seguro?',
                    description: `Estas a punto de ${form.updateType === 'Aumentar precios' ? 'aumentar' : 'disminuir'} 
                un ${form.percentage}% el precio de los productos de ${brandsSelectedText}.`,
                    icon: 'warning',
                    confirmButtonText: 'Si, estoy seguro',
                    handleConfirm: async () => {

                        dispatch(startLoading())

                        await startUpdateAllProductPricesQuery(form, token!, dispatch);

                        showMixinToast('Precios actualizados', 'success');

                        setVisible(false);

                    }
                })

            }

        }

    }

    const handleBrandChange = (brand: number) => {

        if (brand === 0) {
            setForm({ ...form, brandsSelected: [] });
        } else {

            if (form.brandsSelected.includes(brand)) {

                const newBrands = form.brandsSelected.filter(brandElement => { return brandElement !== brand })

                setForm({ ...form, brandsSelected: newBrands });

            } else {
                const newBrands = form.brandsSelected.length === 0 ? [brand] : [...form.brandsSelected, brand];

                setForm({ ...form, brandsSelected: newBrands });
            }
        }
    };


    // useEffect(() => {

    //     console.log(JSON.stringify(form, null, 2));

    // }, [form])

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Actualizar precios
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(false) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <ContentContainer>

                    <Typography size='16px' bold>
                        Elija las marcas de los productos que desea actualizar su precio:
                    </Typography>

                    <Checkbox
                        type="checkbox"
                        text="Todas las marcas"
                        checked={form.brandsSelected.length === 0}
                        onClick={() => { handleBrandChange(0) }}
                        style={{ width: 'fit-content', alignSelf: 'start', marginTop: 20 }}
                    />

                    {

                        checkingBrands ? (
                            <LoadingComponent />
                        )
                            : (

                                brands.map((brand: BrandProps) => {

                                    return (

                                        <Checkbox
                                            type="checkbox"
                                            key={brand.id}
                                            text={brand.name}
                                            checked={form.brandsSelected.includes(brand.id)}
                                            onClick={() => { handleBrandChange(brand.id) }}
                                            style={{ width: 'fit-content', alignSelf: 'start', marginTop: 20 }}
                                        />

                                    )
                                })

                            )

                    }

                    <Typography size='16px' bold style={{ marginTop: 30 }}>
                        Indique el monto a actualizar:
                    </Typography>

                    <UpdateContainer>

                        <Input
                            label="¿Aumentar o disminuir los precios?"
                            type="select"
                            value={form.updateType}
                            onChange={(e) => {

                                setForm({ ...form, updateType: e.target.value as UpadateTypes });
                            }}
                        >
                            <option value={'Aumentar precios' as UpadateTypes}>Aumentar precios</option>
                            <option value={'Disminuir precios' as UpadateTypes}>Disminuir precios</option>
                        </Input>

                        <Input
                            type="number"
                            label={`Porcentaje a actualizar (${formatPrice(!Number.isNaN(form.percentage) ? form.percentage : 0)}%)`}
                            value={form.percentage === 0 || Number.isNaN(form.percentage) ? '' : form.percentage}
                            placeholder="Porcentaje de actualización"
                            incomplete={(form.percentage > 0 || !fieldIncomplete) ? false : fieldIncomplete.percentage}
                            onChange={(e) => {

                                // if (/^[0-9]*$/.test(e.target.value)) {

                                if (setFieldIncomplete && fieldIncomplete) { setFieldIncomplete({ ...fieldIncomplete, percentage: false }); }

                                setForm({ ...form, percentage: parseFloat(e.target.value) });

                                // }

                                console.log(form.percentage);


                            }}
                        />

                    </UpdateContainer>

                </ContentContainer>

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={Number.isNaN(form.percentage) || form.percentage <= 0 || checkingBrands ? 'Indique el porcentaje a actualizar' : 'Aumentar todos los productos'}
                    secondaryButtonText='Cerrar'
                    primaryDisabled={Number.isNaN(form.percentage) || form.percentage <= 0 || checkingBrands}
                    secondaryDisabled={false}
                    onClickPrimary={() => {

                        // Funcion a back para actualizar

                        handleFormConfirm()


                    }}
                    onClickSecondary={() => {
                        setVisible(false);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
