import { UiReducerProps } from '../interfaces/ReducerInterfaces';
import { types } from '../types/types';

const initialState: UiReducerProps = {
    loading: false,
    msgError: null,
    menuBar: false
}

export const uiReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }

        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }

        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }

        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        default:
            return state;

    }

}