import { styled } from "styled-components";
import { device } from "../../styles/stylesConfig";
import { CSSProperties } from "react";
import { Button } from "../molecules/Button";

interface Props {
    primaryButtonText?: string;
    secondaryButtonText?: string;
    terciaryButtonText?: string;
    primaryDisabled?: boolean;
    secondaryDisabled?: boolean;
    terciaryDisabled?: boolean;
    onClickPrimary?: () => void;
    onClickSecondary?: () => void;
    onClickTerciary?: () => void;
    style?: CSSProperties
}

const ButtonsContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
position: relative;

    flex-direction: column;
    align-items: end;

& > div {
    display: flex;
    flex-direction: row;

    @media ${device.sm} {
        flex-direction: column-reverse;
        width: 100%;
    }
}

& > div > button {
    height: 40px !important ;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0px;

    @media ${device.lg} {
        /* margin-bottom: 20px; */
    }

    @media ${device.sm} {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;

    }
}

& > div:nth-child(1) > button:first-child {
    margin-bottom: 20px;
}

& > div:nth-child(2) > button:first-child {

    @media ${device.lg} {
        margin-bottom: 0px
    }
}
`

export const FooterButtonsContainer = ({
    style,
    primaryButtonText,
    secondaryButtonText,
    terciaryButtonText,
    primaryDisabled,
    secondaryDisabled,
    terciaryDisabled,
    onClickPrimary,
    onClickSecondary,
    onClickTerciary
}: Props) => {

    return (

        <>

            <ButtonsContainer style={{ ...style }}>

                <div >

                    {terciaryButtonText && onClickTerciary &&
                        <Button
                            type="secondary"
                            text={terciaryButtonText ?? ''}
                            onClick={() => onClickTerciary()}
                            disabled={terciaryDisabled}
                        />
                    }

                </div>

                <div>

                    {secondaryButtonText && onClickSecondary &&
                        <Button
                            type="secondary"
                            text={secondaryButtonText ?? ''}
                            onClick={() => onClickSecondary()}
                            disabled={secondaryDisabled}
                        />
                    }

                    {primaryButtonText && onClickPrimary &&
                        <Button
                            type="confirm"
                            text={primaryButtonText ?? ''}
                            onClick={() => onClickPrimary()}
                            disabled={primaryDisabled}
                        />
                    }

                </div>

            </ButtonsContainer>

        </>

    )
}
