import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { SignInScreen } from '../screens/auth/SignInScreen';
import { SignUpScreen } from '../screens/auth/SignUpScreen';
import { ForgetPasswordScreen } from "../screens/auth/ForgetPasswordScreen";
import { NewPasswordScreen } from '../screens/auth/NewPasswordScreen';
import { AuthStatusScreen } from "../screens/auth/AuthStatusScreen";
import { AppLogoComponent } from "../components/molecules/AppLogoComponent";
import { SecondFactorScreen } from "../screens/auth/SecondFactorScreen";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { screens } from "../constants/NavigationConstants";

const MainContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    background: url(${require('../images/tools1.jpg')}) center no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
`

const BoxContainer = styled.div`
    height: 100%;
    max-width: 550px;
    width: 550px;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 10px 0px 6px #00000026;
    display: flex;
    justify-content: center;

    @media ${device.lg} {
        width: 100% !important;
        max-width: 100% !important;
    }
`

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    padding: 50px;
`

const BackgroundImage1 = styled.img`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 250px;
    height: 250px;
`

const BackgroundImage2 = styled.img`
    position: absolute;
    top: 0px;
    right: 5px;
    width: 120px;
    height: 120px;
`

export const PublicRouter = () => {
    return (

        <MainContainer >

            <BoxContainer>

                <ContentContainer>

                    <BackgroundImage1
                        src={require('../images/casco.png')}
                        alt='logo'
                    />

                    <BackgroundImage2
                        src={require('../images/herramientas.png')}
                        alt='logo'
                    />

                    <AppLogoComponent />

                    <Switch>

                        <Route
                            exact
                            path={`/auth/${screens.signin}`}
                            component={SignInScreen}
                        />

                        <Route
                            exact
                            path={`/auth/${screens.signup}`}
                            component={SignUpScreen}
                        />

                        <Route
                            exact
                            path={`/auth/${screens.forgotPass}`}
                            component={ForgetPasswordScreen}
                        />

                        <Route
                            exact
                            path={`/auth/${screens.authStatus}`}
                            component={AuthStatusScreen}
                        />

                        <Redirect to={`/auth/${screens.signin}`} />

                    </Switch>

                </ContentContainer>

            </BoxContainer>

        </MainContainer>

    )
}
