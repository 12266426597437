import validator from 'validator';
import { useDispatch } from 'react-redux';
import { setError } from '../../actions/ui';
import { LinkButton } from '../../components/molecules/LinkButton';
import { useState } from 'react';
import { AuthTitleComponent } from '../../components/molecules/AuthTitleComponent';
import { startSendPasswordResetEmail } from '../../actions/auth';
import { Button } from '../../components/molecules/Button';
import { Input } from '../../components/molecules/Input';

export const ForgetPasswordScreen = () => {

    const dispatch = useDispatch();

    const [values, setValues] = useState<{ email: string }>({
        email: ''
    })

    const { email } = values;

    const handleForgetPassword = () => {

        if (isFormValid()) {

            dispatch(startSendPasswordResetEmail(email));

        }

    }

    const isFormValid = () => {

        if (!validator.isEmail(email)) {
            dispatch(setError('El email no es valido'));
            return false;
        } else {

            return true;
        }

    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleForgetPassword();
        }
    };

    return (

        <>

            <AuthTitleComponent
                text={`Recuperá tu contraseña ahora y seguí gestionando tus tareas`}
            />

            <Input
                type='text'
                label='Tu correo electrónico'
                placeholder='Correo electrónico'
                value={email}
                description='Te enviaremos un mail a tu correo electrónico para verificar que sos vos. En el mismo vas a encontrar un link para poder generar tu nueva contraseña.'
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        email: e.target.value.toLowerCase()
                    })
                }}
            />

            <Button
                type='primary'
                text='Enviar correo ahora'
                fullWidth
                onClick={() => handleForgetPassword()}
            />

            <LinkButton
                label='¿Todavía no te creaste tu cuenta?'
                text='Registrate ahora'
                link='/auth/iniciar-sesion'
            />


        </>

    )
}
