import styled from "styled-components"
import { colors } from "../../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    z-index: 1;
`

const Text = styled.p`
    color: ${colors.primary_dark};
    font-size: 14px;
    margin-top: 20px;
`

interface Props {
    text: string
}

export const ParagraphComponent = ({ text }: Props) => {

    return (

        <Container>

            <Text>{text}</Text>

        </Container>


    )
}
