import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { AssignBarcodeProductProps, ProductProps } from "../../interfaces/ProductInterfaces";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { ProductsListView } from "./ProductsListView";
import { ProductBrandsHeader } from "./ProductBrandsHeader";
import { Cancel01Icon } from "@hugeicons/react-pro";
import { showMixinToast, showModalConfirm } from "../../utils/Alerts";
import { startLoading } from "../../actions/ui";
import { startAssignCodeBarToProductQuery } from "../../queries/ProductQueries";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 95%;
    height: 95%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    padding-top: 120px;
    background-color: ${colors.primary};
    overflow-y: scroll;
    position: relative;

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 93%;
    max-height: 95%;
    display: flex;
    justify-content: space-between;
    background-color: ${colors.primary};
    margin-bottom: 0px;
    padding: 25px 50px;
    border-bottom: 1px solid ${colors.light};
    position: fixed;
    border-radius: 10px 10px 0px 0px;
    left: 10px;
    right: 10px;
    top: 2.5%;
    margin: auto;
    z-index: 2;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.light};

        @media ${device.sm} {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

interface Props {
    data: string | null;
    setData: React.Dispatch<React.SetStateAction<string | null>>;
    onAssingCodeToExistingProduct: () => void;
}

export const AssignCodeToProductModal = ({ data, setData, onAssingCodeToExistingProduct }: Props) => {

    const { products } = useSelector((state: StoreProps) => state.fallherramientas);

    const { token } = useSelector((state: StoreProps) => state.auth);

    const [searchProduct, setSearchProduct] = useState<string>('');

    const [brandSelected, setBrandSelected] = useState<number | null>(null);

    const dispatch = useDispatch();

    const getFilteredProductsList = (list: ProductProps[], brandSelected: number | null, searchProduct: string) => {

        let filteredList = list

        if (brandSelected !== null) {
            filteredList = filteredList.filter((value) => { return value.id === brandSelected });
        }

        if (searchProduct.trim() !== '') {
            filteredList = filteredList.filter((value) => { return value.name.toLowerCase().includes(searchProduct.toLocaleLowerCase().trim()) || value.model.toLowerCase().includes(searchProduct.toLocaleLowerCase().trim()) });
        }

        return filteredList;

    }


    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Asignar código
                    </Typography>

                    <Button
                        type="icon"
                        iconElement={<Cancel01Icon size={25} color={colors.light} />}
                        onClick={() => {
                            setData(null);
                        }}
                        style={{ zIndex: 2 }}
                    />

                </HeaderContainer>

                <ProductBrandsHeader
                    brandSelected={brandSelected}
                    setBrandSelected={setBrandSelected}
                    searchProduct={searchProduct}
                    setSearchProduct={setSearchProduct}
                    style={{
                        border: 'none',
                        marginTop: 0,
                        paddingTop: 0,
                    }}
                />

                <ProductsListView
                    productSearched={searchProduct}
                    data={getFilteredProductsList(products, brandSelected, searchProduct)}
                    onClickProduct={(product: ProductProps) => {

                        let msg = ''

                        if (data) {


                            if (product.barCode === null) {

                                msg = `Estás a punto de asignar el código [${data.toUpperCase()}] al producto [${product.name.toUpperCase()}].`

                            }

                            if (product.barCode !== null && data !== product.barCode) {

                                msg = `El producto [${product.name.toUpperCase()}] ya tiene un código asignado. 
                            ¿Querés reemplazar el código actual [${product.barCode.toUpperCase()}] por el nuevo código [${data.toUpperCase()}].?`

                            }



                            showModalConfirm({
                                title: '¿Estas seguro?',
                                description: msg,
                                icon: 'warning',
                                confirmButtonText: 'Confirmar',
                                confirmButtonColor: colors.ok,
                                cancelButtonColor: colors.danger,
                                handleConfirm: async () => {

                                    dispatch(startLoading())

                                    const body: AssignBarcodeProductProps = {
                                        id: product.id,
                                        barCode: data
                                    }

                                    await startAssignCodeBarToProductQuery(body, token!, dispatch);

                                    onAssingCodeToExistingProduct()

                                    setData(null);

                                    showMixinToast('Código asignado', 'success')

                                }
                            })

                        }


                    }}
                />


                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    secondaryButtonText={'Cancelar'}
                    secondaryDisabled={false}
                    onClickSecondary={() => {
                        setData(null);
                    }}
                />



            </ModalCardContainer>

        </ShadowContainer>

    )
}
