import axios from "axios"
import { finishLoading, setError } from "../actions/ui"
import { endpoints } from "../helpers/FirebaseHelper"
import { AssignBarcodeProductProps, ProductProps, UpdateAllProductPricesProps, UpdateCustomProfitProductProps, UpdateGlobalProfitProps, UpdatePriceProductProps, UpdateStockProductProps } from "../interfaces/ProductInterfaces"
import { DeleteSoldProps } from "../interfaces/SoldInterfaces"
import { showMixinToast } from "../utils/Alerts"

export const startcreateUpdateProductQuery = async (body: ProductProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createUpdateProduct}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(() => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {

                    if (e.response.data.trace.includes('auth/email-already-in-use') || e.response.data.trace.includes('Error: The email address is already in use by another account')) {
                        msg = 'El email ya se encuentra registrado.'
                    } else {
                        msg = e.response.data.message
                    }

                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const startUpdatePriceQuery = async (body: UpdatePriceProductProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.updatePrice}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(() => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {

                    if (e.response.data.trace.includes('auth/email-already-in-use') || e.response.data.trace.includes('Error: The email address is already in use by another account')) {
                        msg = 'El email ya se encuentra registrado.'
                    } else {
                        msg = e.response.data.message
                    }

                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })


}

export const startUpdateCustomProfitQuery = async (body: UpdateCustomProfitProductProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.updateCustomProfit}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(() => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {

                    if (e.response.data.trace.includes('auth/email-already-in-use') || e.response.data.trace.includes('Error: The email address is already in use by another account')) {
                        msg = 'El email ya se encuentra registrado.'
                    } else {
                        msg = e.response.data.message
                    }

                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })


}

export const startUpdateStockQuery = async (body: UpdateStockProductProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.updateStock}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async () => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const startAssignCodeBarToProductQuery = async (body: AssignBarcodeProductProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.assignCodeBar}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async () => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const startUpdateGlobalProfitValueQuery = async (body: UpdateGlobalProfitProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.updateGlobalProfit}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async () => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const startUpdateAllProductPricesQuery = async (body: UpdateAllProductPricesProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.updateAllPrices}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async () => {

            dispatch(finishLoading())

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}

export const startDeleteProductQuery = async (body: DeleteSoldProps, token: string, dispatch: any) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.deleteProduct}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
    )
        .then(async () => {

            dispatch(finishLoading())

            showMixinToast('Producto eliminado', 'success')

        }).catch((e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            dispatch(setError(msg));

        })

}