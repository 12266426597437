import styled from "styled-components"
import { Logo } from "./Logo"
import { colors, device, fonts } from "../../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    z-index: 1;
    margin-top: 20px;
`

const Name = styled.h1`
    color: ${colors.primary_dark};
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 3.4px;
    line-height: normal;
    font-family: ${fonts.main};
    margin-left: 10px;

    @media ${device.sm} {
        font-size: 18px;
    }
`

export const AppLogoComponent = () => {

    return (

        <Container>

            <Logo />

            <Name>FAL Herramientas</Name>

        </Container>

    )
}
