import { styled } from "styled-components";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CSSProperties } from "react";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`

interface Props {
    headerElement: JSX.Element;
    bodyElement: JSX.Element;
    containerStyle?: CSSProperties;
    headerStyle?: CSSProperties;
    bodyStyle?: CSSProperties;
    defaultExpanded?: boolean;
}

export const CollapsableSection = ({ headerElement, bodyElement, containerStyle, headerStyle, bodyStyle, defaultExpanded }: Props) => {

    return (

        <MainContainer>

            <Accordion
                defaultExpanded={defaultExpanded}
                style={{
                    width: '100%',
                    ...containerStyle
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{
                        ...headerStyle
                    }}
                >

                    {headerElement}

                </AccordionSummary>

                <AccordionDetails
                    style={{
                        ...bodyStyle
                    }}
                >

                    {bodyElement}

                </AccordionDetails>

            </Accordion>

        </MainContainer>

    )
}