import { useHistory } from "react-router-dom"
import { Button } from "./Button"
import styled from "styled-components"
import { colors, device } from "../../styles/stylesConfig"

interface Props {
    label?: string
    text: string
    link: string
    params?: object
    onClick?: () => void
}

const Label = styled.h1`
    color: ${colors.primary_dark};
    font-size: 16px;
    font-weight: normal;
    margin-top: 30px;
    margin-bottom: 5px;
    z-index: 1;

    @media ${device.sm} {
        font-size: 14px;
    }

`

export const LinkButton = ({ label, text, link, params, onClick }: Props) => {

    const history = useHistory();

    return (

        <>

            <Label>{label ?? ''}</Label>

            <Button
                type='terciary'
                text={text}
                onClick={() => {

                    history.push(link, params);

                    onClick && onClick()

                }}
            />
        </>

    )
}
