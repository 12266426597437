import { styled } from 'styled-components';
import { device } from '../../styles/stylesConfig';
import { CSSProperties, ReactNode } from 'react';
import { Header } from '../organisms/Header';

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100%;
    position: relative;
`

const ProyectDataContainer = styled.div`
    min-width: 100%;
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: end;
    padding-top: 70px;

    & > h1:first-child {
        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }
    @media ${device.sm} {
        align-items: start;
        text-align: center;
    }
`

const SectionScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;

    @media ${device.lg} {
        width: 100%;
        min-height: auto;
        box-shadow: none;
        border: none;
        border-radius: 0px;
        margin-left: 0px;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    
    & > h1:first-child {
        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }

    @media ${device.sm} {
        align-items: center;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
`

interface Props {
    children?: ReactNode;
    style?: CSSProperties
}

export const ScreenContainer = ({ children, style }: Props) => {

    return (

        <MainContainer style={{ ...style }}>

            <Header />

            <ProyectDataContainer style={{ ...style }}>

                <SectionScreenContainer style={{ ...style }}>

                    <ContentContainer style={{ ...style }}>

                        {children}

                    </ContentContainer>

                </SectionScreenContainer>

            </ProyectDataContainer>

        </MainContainer>

    )
}
