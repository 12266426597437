import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../molecules/Input";
import { Cancel01Icon } from "@hugeicons/react-pro";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { showMixinToast } from "../../utils/Alerts";
import { ClearButton } from "../atoms/ClearButton";
import { ProductProps } from "../../interfaces/ProductInterfaces";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 95%;
    max-width: 800px;
    max-height: 75%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.md} {
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 21px;
        }

    }
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > h1 {
        width: 100%;
        text-align: center;
        white-space: normal;
        color: ${colors.grey_dark};
        margin-top: 10px;
        border-bottom: 1px solid ${colors.grey_normal};
        padding-bottom: 10px;
    }
`

const UpdateContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
        width: 100%;
        max-width: 290px;
    }
    
`


const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.grey_normal};
    padding: 30px 0px;

    & > button {
        margin-top: 0px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 15px 20px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;

            &:last-of-type {
                margin-top: 20px;
            }
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 

`

interface Props {
    notFoundProduct: boolean;
    code: string;
    setNotFoundProduct: React.Dispatch<React.SetStateAction<boolean>>;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setData: React.Dispatch<React.SetStateAction<ProductProps | null>>;
    onSetCodeToNewProduct: (c: string) => void;
    onSetAssignCodeToProduct: (c: string) => void;
    onScan: () => void;
}

export const ScanProductModal = ({ notFoundProduct, code, setCode, setNotFoundProduct, setVisible, setData, onSetCodeToNewProduct, onSetAssignCodeToProduct, onScan }: Props) => {

    const { products } = useSelector((state: StoreProps) => state.fallherramientas);

    const [fieldIncomplete, setFieldIncomplete] = useState({
        barcode: false,
    })

    const [barcode, setBarcode] = useState(code);

    const [tienePrefijo, setTienePrefijo] = useState(false);

    const dispatch = useDispatch()

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            barcode: barcode.trim() === '',
        });

        if (barcode.trim() === '') {
            existError = true;
        }

        return existError;

    }

    const findProduct = async (code: string) => {

        const productFounded = products.find((value) => { return value.barCode === code })

        if (productFounded) {
            console.log('==========');
            console.log('ENCONTRADO');
            console.log('==========');

            setVisible(false);
            setData(productFounded);
            setNotFoundProduct(false)
        } else {
            console.log('==========');
            console.log('NOT FOUND');
            console.log('==========');

            showMixinToast('Producto no encontrado', 'warning')

            setData(null);

            setNotFoundProduct(true)

            onScan()

            setTimeout(() => {

                setBarcode(code);

            }, 300);

        }

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            setCode(barcode.replace('%A%', '').replace('%Z%', ''));
            findProduct(barcode.replace('%A%', '').replace('%Z%', ''));
            setBarcode('');

        }

    }

    const inputRef: any = useRef(null);

    const handleChange = (event: any) => {

        setBarcode(event.target.value);

        setNotFoundProduct(false)

        if (event.target.value.startsWith('%A%')) {
            setTienePrefijo(true);
        } else {
            setTienePrefijo(false);
        }
    };

    useEffect(() => {

        const timerId = setTimeout(() => {

            if (barcode.includes('%A%') && barcode.includes('%Z%')) {

                console.log('Código de barras: ' + barcode.replace('%A%', '').replace('%Z%', ''));

                const regex = /%A%(.*?)%Z%/;
                const match = barcode.match(regex);

                if (match && match.length > 1) {
                    setCode(match[1].replace('%A%', '').replace('%Z%', ''));
                    setBarcode(match[1].replace('%A%', '').replace('%Z%', ''));
                    findProduct(match[1].replace('%A%', '').replace('%Z%', ''));
                }

                setBarcode('')
            }
            const newValor = barcode;

            console.log('code:', code);
            console.log('Valor completo:', newValor);

        }, 200);

        return () => clearTimeout(timerId);
    }, [barcode]);

    useEffect(() => {

        const handleKeyDown = (event: any) => {
            if (/^[%]$/i.test(event.key)) {
                inputRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [tienePrefijo]);

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        {!notFoundProduct ? 'Buscar producto' : 'Producto no encontrado'}
                    </Typography>

                    <Button
                        type="icon"
                        iconElement={<Cancel01Icon size={25} color={colors.dark} />}
                        onClick={() => {

                            setVisible(false)

                            setNotFoundProduct(false)
                        }}
                    />


                </HeaderContainer>

                <ContentContainer
                    style={{

                    }}
                >

                    <Typography
                        size='16px'
                        bold
                        style={{
                            border: `1px solid ${colors.primary_light}`,
                            backgroundColor: colors.primary,
                            color: colors.light,
                            padding: 15,
                            textTransform: 'uppercase',
                            borderRadius: 5
                        }}
                    >
                        Use el lector y escanee el código de barras del producto
                    </Typography>

                    <Typography
                        size='16px'
                        bold
                        style={{
                            marginTop: 30,
                            textTransform: 'uppercase',
                            padding: 10,
                            borderTop: `1px solid ${colors.grey_normal}`
                        }}
                    >
                        O Ingreselo manualmente
                    </Typography>

                    <UpdateContainer>

                        <Input
                            clearButton={barcode.trim() !== '' ? <ClearButton onClick={() => { setBarcode(''); setNotFoundProduct(false); }} /> : <></>}
                            reference={inputRef}
                            type="text"
                            label="Código de barras"
                            value={barcode}
                            incomplete={fieldIncomplete.barcode}
                            placeholder="Ingresar código"
                            onChange={handleChange}
                        />


                    </UpdateContainer>

                    {
                        notFoundProduct && (

                            <>

                                <Typography size='16px' bold style={{ marginTop: 50 }}>
                                    ¿Querés agregar este código de barras a tu stock?
                                </Typography>

                                <ActionsContainer>

                                    <Button
                                        type="primary"
                                        text="Agregar nuevo producto con este código"
                                        style={{
                                            width: '100%',
                                            textTransform: 'uppercase',
                                            backgroundColor: colors.grey_light,
                                            color: colors.primary,
                                            border: `2px solid ${colors.primary}`,
                                            whiteSpace: 'normal'

                                        }}
                                        onClick={() => { onSetCodeToNewProduct(barcode) }}
                                    />

                                    <Button
                                        type="primary"
                                        text="Asignar este código a un producto existente"
                                        style={{
                                            width: '100%',
                                            textTransform: 'uppercase',
                                            backgroundColor: colors.grey_light,
                                            color: colors.primary,
                                            border: `2px solid ${colors.primary}`,
                                            whiteSpace: 'normal'
                                        }}
                                        onClick={() => {
                                            onSetAssignCodeToProduct(barcode)
                                        }}
                                    />

                                </ActionsContainer>

                            </>

                        )
                    }

                </ContentContainer>

                <FooterButtonsContainer
                    style={{ marginTop: 50, alignSelf: 'center', justifyContent: 'center' }}
                    primaryButtonText={barcode.trim() === '' ? 'Escanee o ingrese un código' : 'Buscar producto'}
                    secondaryButtonText='Cerrar'
                    primaryDisabled={barcode.trim() === ''}
                    secondaryDisabled={false}
                    onClickPrimary={() => {

                        // Funcion a back para actualizar

                        handleFormConfirm()


                    }}
                    onClickSecondary={() => {
                        setVisible(false);

                        setNotFoundProduct(false)
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
