import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Button } from "../molecules/Button";
import { Input } from "../molecules/Input";
import { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { ClearButton } from "../atoms/ClearButton";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px solid ${colors.primary_dark};
    z-index: 1;
    
    @media ${device.lg} {
        padding-top: 10px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    @media ${device.md} {
        align-items: start;
        padding-top: 0px;
    }
`

const OptionButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 5px 20px 10px;

    & > button {
        height: 30px;
        margin-right: 20px;
        font-size: 14px !important;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

    }

    @media ${device.lg} {
        width: fit-content;
    
        & > button {
            margin-right: 10px;
        }
    }

    @media ${device.md} {
        width: 100%;
    
        & > button {
            margin-right: 10px;
            font-size: 12px !important;
        }
    }
    
`

interface Props {
    brandSelected: number | null
    searchProduct: string
    setSearchProduct: React.Dispatch<React.SetStateAction<string>>
    setBrandSelected: React.Dispatch<React.SetStateAction<number | null>>
    style?: CSSProperties
}

export const ProductBrandsHeader = ({ searchProduct, brandSelected, setSearchProduct, setBrandSelected, style }: Props) => {

    const { brands } = useSelector((state: StoreProps) => state.fallherramientas);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWindowWidth = () => {

        setWindowWidth(window.innerWidth);

    }

    useEffect(() => {

        window.addEventListener('resize', updateWindowWidth)

        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }

    }, [])

    return (

        <MainContainer style={{ ...style }}>

            <OptionButtonsContainer>

                <Button
                    key={0}
                    type={brandSelected === null ? 'full light' : 'light'}
                    text={'TODAS'}
                    style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark }}
                    onClick={() => { setBrandSelected(null) }}
                />

                {

                    brands.map((value) => {

                        const { id, name } = value

                        return (

                            <Button
                                key={id}
                                type={brandSelected === id ? 'full light' : 'light'}
                                text={name.toUpperCase()}
                                style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark }}
                                onClick={() => { setBrandSelected(id) }}
                            />

                        )
                    })
                }

            </OptionButtonsContainer>

            <Input
                style={{
                    marginBottom: windowWidth <= 1280 ? 30 : 16,
                    width: windowWidth > 1280 ? 400 : '90%',
                    backgroundColor: colors.light,
                    alignSelf: windowWidth <= 1280 ? 'center' : undefined,
                    maxWidth: 798
                }}
                type="text"
                label="Buscar producto"
                placeholder="Escribí acá..."
                theme="dark"
                value={searchProduct}
                onChange={(e) => {

                    if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {

                        setSearchProduct(e.currentTarget.value);

                    }
                }}
                clearButton={searchProduct.trim() !== '' ? <ClearButton onClick={() => { setSearchProduct('') }} /> : <></>}
            />

        </MainContainer>

    )
}