import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { AccessProps, DeleteMemberProps } from "../../interfaces/AuthInterfaces";
import { Button } from "./Button";
import { useState } from "react";
import { showMixinToast, showModalConfirm } from "../../utils/Alerts";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { startLoading } from "../../actions/ui";
import { starDeleteMemberQuery } from "../../queries/AuthQueries";

const ButtonCard = styled.button`
    background-color: white;
    border-radius: 5px;
    width: 300px;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
    border: ${colors.primary_light} 1px solid;
    box-shadow: 0px 4px 6px #00000050;
    align-self: center;
    justify-self: center;

    @media ${device.xl} {
        width: 260px;
        height: 250px;
    }

    @media ${device.sm} {
        width: 350px;
        height: 200px;
        padding: 15px;

    }

    @media ${device.xs} {
        width: 250px;
        height: 200px;
        padding: 10px;
    }

`


const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ${device.sm} {

        h1:first-child {
            font-size: 12px;
        }
        h1:last-child {
            font-size: 12px;
        }
    }

    @media ${device.xs} {

        h1:first-child {
            font-size: 10px;
        }
        h1:last-child {
            font-size: 10px;
        }
    }

`

const ActionsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin-top: 20px;

`

interface Props {
    data: AccessProps;
    onClick: () => void
}

export const MemberCardItem = ({ data, onClick }: Props) => {

    const [isMember] = useState(data.role !== 'None');

    const { name, token } = useSelector((state: StoreProps) => state.auth);

    const dispatch = useDispatch()

    const handleDelete = () => {

        showModalConfirm({
            title: '¿Estas seguro?',
            description: isMember ? `Estas a punto de eliminar de manera permanente al miembro "${data.name}"` : `Estas a punto de denegar el acceso a "${data.name}"`,
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            confirmButtonColor: colors.ok,
            cancelButtonColor: colors.danger,
            handleConfirm: async () => {

                dispatch(startLoading())

                const body: DeleteMemberProps = {
                    email: data.email
                }

                await starDeleteMemberQuery(body, token!, dispatch);

                showMixinToast(isMember ? `Miembro eliminado` : `Acceso denegado`, 'success');
            }
        })

    }

    return (

        <ButtonCard onClick={() => { }}>


            <Typography
                size='16px'
                color={colors.primary}
                bold
                style={{
                    width: '100%',
                    textTransform: 'uppercase',
                    whiteSpace: 'normal',
                    marginBottom: 20
                }}
            >
                {data.role !== 'None' ? data.role : 'Sin rol'}
            </Typography>

            <Typography
                size='16px'
                bold
                style={{
                    width: '100%',
                    textTransform: 'uppercase',
                    whiteSpace: 'normal',
                }}
            >
                {name === data.name ? data.name + " (yo)" : data.name}
            </Typography>

            <Typography
                size='14px'
                style={{
                    marginTop: 5,
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                }}
            >
                {data.email}
            </Typography>

            {
                (name !== data.name && data.role !== 'Administrador') && (

                    <ActionsDiv>

                        <Button
                            type="confirm"
                            text={isMember ? 'Eliminar' : "Rechazar"}
                            style={{ backgroundColor: colors.danger, margin: 0, padding: '10px 15px' }}
                            onClick={() => { handleDelete() }}
                        />

                        <Button
                            type="confirm"
                            text={isMember ? 'Modificar rol' : "Asignar rol"}
                            style={{ backgroundColor: colors.info, margin: 0, padding: '10px 15px' }}
                            onClick={() => { onClick() }}
                        />

                    </ActionsDiv>

                )
            }

        </ButtonCard>

    )
}
