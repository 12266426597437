import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { login } from '../actions/auth';
import { PublicRouter } from './PublicRouter';
import { PrivateRouter } from './PrivateRouter';
import Swal from 'sweetalert2';
import { removeError } from '../actions/ui';
import styled from 'styled-components';
import { LoadingScreen } from '../components/templates/LoadingScreen';
import { screens } from '../constants/NavigationConstants';
import '../firebase/firebase-config'
import { StoreProps } from '../interfaces/ReducerInterfaces';
import { formatExpirationTime, getFullDate } from '../utils/Utilities';

const RouterContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: inline-block;
    height: 100vh;
`

export const AppRouter = () => {

    const dispatch = useDispatch();

    const { msgError, loading } = useSelector((state: StoreProps) => state.ui);

    const [checking, setChecking] = useState(true);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {

        const auth = getAuth();

        onAuthStateChanged(auth, async (user) => {
            if (user?.uid) {

                console.log(JSON.stringify(user, null, 2));

                const tokenResult = await user.getIdTokenResult(true)

                dispatch(login({
                    uid: user.uid,
                    name: user.displayName,
                    token: tokenResult.token,
                    role: tokenResult.claims.role,
                    expirationTime: formatExpirationTime(tokenResult.expirationTime)
                }))

                setIsLoggedIn(true);

                if (user.emailVerified) {

                    if (!isVerified) {

                        setIsVerified(true);

                    }

                } else {

                    setIsVerified(false);

                }


            } else {

                setIsLoggedIn(false);

            }

            setChecking(false);

        })

    }, [isVerified, dispatch, setChecking, setIsLoggedIn, setIsVerified]);

    useEffect(() => {

        const setErrorModal = async () => {

            if (msgError !== null) {

                await Swal.fire({
                    titleText: 'Oops...',
                    html: msgError,
                    icon: 'error',
                }).then(() => {

                    if (msgError.includes('se venció tu sesión')) {

                        window.location.reload()

                    }

                    dispatch(removeError())


                })

            }

        }
        if (msgError !== null)
            setErrorModal();


    }, [dispatch, msgError])

    if (checking) {

        return <LoadingScreen />
    }

    return (

        <Router>

            <RouterContainer>

                {loading && <LoadingScreen />}

                <Switch>

                    {
                        (isLoggedIn)
                            ? (
                                <Route
                                    exact
                                    component={PrivateRouter}
                                />
                            )
                            : (
                                <Route
                                    exact
                                    component={PublicRouter}
                                />
                            )
                    }


                    <Redirect to={`/auth/${screens.signin}`} />

                </Switch>

            </RouterContainer>

        </Router>

    )
}
