import { screens } from "../constants/NavigationConstants";

export const getScreenName = (pathname: string) => {

    if (pathname.includes(screens.home)) {
        return screens.home;
    } else if (pathname.includes(screens.statistics)) {
        return screens.statistics;
    } else if (pathname.includes(screens.profile)) {
        return screens.profile;
    } else {
        return '';
    }
};
