import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Button } from "../molecules/Button";
import { ActionButtonsProps } from "../../interfaces/ProductInterfaces";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.sm} {
        flex-direction: column;
        justify-content: center;
    }
`

const OptionButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 5px 20px 10px;

    &::-webkit-scrollbar {
          height: 10px;
      }

    & > button {
        height: 30px;
        margin-right: 20px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

    }

    @media ${device.xl} {
        & > button {
            min-width: 140px;
            white-space: normal !important;
            height: fit-content !important;

            @media ${device.md} {
                & {
                    min-width: 120px;
                    font-size: 14px !important;
                }
            }

        }
    }
    
`

interface Props {
    data: ActionButtonsProps[]
}

export const OptionButtons = ({ data }: Props) => {

    return (

        <MainContainer>

            <OptionButtonsContainer>

                {
                    data.map((value) => {

                        const { id, name, iconElement, type, styles, handleClick } = value

                        return (

                            <Button
                                key={id}
                                type={type}
                                text={name}
                                iconElement={iconElement}
                                style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark, ...styles }}
                                onClick={() => { handleClick() }}
                            />

                        )
                    })
                }

            </OptionButtonsContainer>

        </MainContainer>

    )
}