import { useEffect, useState } from 'react';
import { Typography } from '../../components/atoms/Typography';
import { CollapsableSection } from '../../components/molecules/CollapsableSection';
import { StatisticsSection } from '../../components/molecules/StatisticsSection';
import { ScreenContainer } from '../../components/templates/ScreenContainer';
import { colors } from '../../styles/stylesConfig';
import { formatMonthYearText, getYearMonth } from '../../utils/Utilities';
import { filterStatisticDates, filterStatisticProducts } from '../../helpers/StatisticsHelper';
import { useSelector } from 'react-redux';
import { StoreProps } from '../../interfaces/ReducerInterfaces';
import { LoadingComponent } from '../../components/molecules/LoadingComponent';
import { screens } from '../../constants/NavigationConstants';
import { Redirect } from 'react-router-dom';
import { ProductDetailsModal } from '../../components/organisms/ProductDetailsModal';
import { ProductProps } from '../../interfaces/ProductInterfaces';
import { SoldProductModal } from '../../components/organisms/SoldProductModal';
import { SoldProps } from '../../interfaces/SoldInterfaces';

export const StatisticsScreen = () => {

    const { role } = useSelector((state: StoreProps) => state.auth);

    if (role !== 'Administrador') {
        <Redirect to={`/${screens.home}`} />
    }

    const { sold, checkingSold } = useSelector((state: StoreProps) => state.fallherramientas);

    const [statisticDates, setStatisticDates] = useState<any[]>(filterStatisticDates(sold));

    const [productDetailsModalVisible, setProductDetailsModalVisible] = useState<ProductProps | null>(null);

    const [soldProductModalVisible, setSoldProductModalVisible] = useState<SoldProps | null>(null);


    useEffect(() => {

        setStatisticDates(filterStatisticDates(sold));

        return () => {
            filterStatisticDates(sold);
        }

    }, [sold])

    return (

        <ScreenContainer>

            {
                checkingSold ? (
                    <LoadingComponent />
                ) : (

                    <>

                        {productDetailsModalVisible !== null &&
                            <ProductDetailsModal
                                detailsOnly
                                data={productDetailsModalVisible}
                                setData={setProductDetailsModalVisible}
                            />
                        }

                        {role === 'Administrador' && soldProductModalVisible !== null &&
                            <SoldProductModal
                                data={soldProductModalVisible}
                                setData={setSoldProductModalVisible}
                            />
                        }


                        <CollapsableSection
                            defaultExpanded
                            containerStyle={{
                                backgroundColor: colors.grey_light
                            }}
                            headerElement={
                                <Typography
                                    size="20px"
                                    bold
                                    style={{
                                        width: '100%',
                                        padding: '5px 0px',
                                        borderRadius: 5,
                                        textTransform: 'uppercase',
                                        textAlign: 'center',
                                    }}
                                >
                                    {formatMonthYearText(parseInt(getYearMonth()))}
                                </Typography>
                            }
                            bodyElement={
                                <StatisticsSection data={filterStatisticProducts(sold, getYearMonth())} setProductDetailsModal={setProductDetailsModalVisible} setSoldProductModalVisible={setSoldProductModalVisible} />
                            }
                        />

                        {
                            statisticDates.filter((value) => { return value !== getYearMonth() }).length > 0 && (

                                <>

                                    <Typography
                                        size="20px"
                                        bold
                                        style={{
                                            borderTop: `1px solid ${colors.dark}`,
                                            borderBottom: `1px solid ${colors.dark}`,
                                            backgroundColor: colors.primary_light,
                                            width: '100vw',
                                            alignSelf: 'center',
                                            padding: '20px 0px',
                                            textTransform: 'uppercase',
                                            textAlign: 'center',
                                            margin: `50px 0`
                                        }}
                                    >
                                        Meses anteriores
                                    </Typography>

                                    {
                                        statisticDates.filter((value) => { return value !== getYearMonth() }).map((monthYear: string) => {

                                            return (

                                                <CollapsableSection
                                                    key={monthYear}
                                                    containerStyle={{
                                                        marginBottom: 20,
                                                        backgroundColor: colors.grey_light
                                                    }}
                                                    headerElement={
                                                        <Typography
                                                            size="20px"
                                                            bold
                                                            style={{
                                                                width: '100%',
                                                                padding: '5px 0px',
                                                                textTransform: 'uppercase',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {formatMonthYearText(parseInt(monthYear))}
                                                        </Typography>
                                                    }
                                                    bodyElement={
                                                        <StatisticsSection data={filterStatisticProducts(sold, monthYear)} setProductDetailsModal={setProductDetailsModalVisible} setSoldProductModalVisible={setSoldProductModalVisible} />

                                                    }
                                                />

                                            )

                                        })
                                    }

                                </>

                            )
                        }

                    </>

                )
            }

        </ScreenContainer>

    )
}
