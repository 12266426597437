import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { ProductProps } from "../../interfaces/ProductInterfaces";
import { Typography } from "../atoms/Typography";
import { ProductCardItem } from "../molecules/ProductCardItem";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
`

const ProductsGrid = styled.div`
    width: fit-content;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    @media ${device.lg} {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

    }

    @media ${device.md} {
        grid-template-columns: repeat(2, 1fr);

    }
   
`

interface Props {
    data: ProductProps[],
    productSearched: string;
    onClickProduct: (product: ProductProps) => any;
}

export const ProductsListView = ({ data, productSearched, onClickProduct }: Props) => {

    return (

        <Container>

            {
                productSearched.trim() !== '' && (

                    <Typography
                        size="24px"
                        bold
                        style={{
                            textTransform: 'uppercase',
                            borderBottom: `5px solid ${colors.light}`,
                            width: 'fit-content',
                            alignSelf: 'center',
                            padding: 10,
                            marginBottom: 50
                        }}
                    >
                        Resultados para "{productSearched}"
                    </Typography>

                )
            }

            <ProductsGrid>

                {data.map((product, index) => (
                    <ProductCardItem key={index} data={product} onClick={() => { onClickProduct(product) }} />
                ))}

            </ProductsGrid>

            {
                data.length === 0 && (

                    <Typography
                        size="16px"
                        color={colors.light}
                        style={{
                            textTransform: 'uppercase',
                            width: 'fit-content',
                            alignSelf: 'center',
                            padding: 10,
                            marginBottom: 20
                        }}
                    >
                        No se encontraron resultados
                    </Typography>

                )
            }


        </Container>

    )
}
