import { Typography } from '../../components/atoms/Typography';
import { ScreenContainer } from '../../components/templates/ScreenContainer';
import { BoxContainer } from '../../components/organisms/BoxContainer';
import { useState } from 'react';
import { styled } from 'styled-components';
import { GenericButton } from '../../components/molecules/Button';
import { colors, device } from '../../styles/stylesConfig';
import { startLogout } from '../../actions/auth';
import { showModalConfirm } from '../../utils/Alerts';
import { useDispatch, useSelector } from 'react-redux';
import { finishLoading, startLoading } from '../../actions/ui';
import { Input } from '../../components/molecules/Input';
import { Logout03Icon, UserIcon, UserMultipleIcon } from '@hugeicons/react-pro';
import { MembersModal } from '../../components/organisms/MembersModal';
import { StoreProps } from '../../interfaces/ReducerInterfaces';
import { startGetMembersQuery, startUpdateFullnameQuery } from '../../queries/AuthQueries';

const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center; 
`

const MembersButton = styled(GenericButton)`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.dark};
    font-weight: normal;
    margin-top: 30px;
    background-color: ${colors.info_light};
    border-color: ${colors.info};
    width: fit-content;
    cursor: pointer;

    @media ${device.sm} {
        width: 100%;
    }
`

const ExitButton = styled(GenericButton)`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.primary_dark};
    font-weight: normal;
    margin-top: 30px;
    background-color: ${colors.danger_light};
    border-color: ${colors.danger};
    width: fit-content;
    cursor: pointer;

    @media ${device.sm} {
        width: 100%;
    }
`

export const ProfileScreen = () => {

    const dispatch = useDispatch()

    const { uid, name, token, role, expirationTime } = useSelector((state: StoreProps) => state.auth);

    const [membersModal, setMembersModal] = useState(false);

    const [form, setForm] = useState({
        fullname: name ?? ''
    });

    const handleUpdateFullName = async () => {

        dispatch(startLoading())

        const body = { displayName: form.fullname };

        const loginDispatch = {
            uid: uid,
            name: form.fullname,
            token: token,
            role: role,
            expirationTime: expirationTime
        }

        await startUpdateFullnameQuery(body, token!, dispatch, loginDispatch);

    }

    const handleLogOut = async () => {

        dispatch(startLoading())

        dispatch(startLogout())

        dispatch(finishLoading())
    }

    return (

        <ScreenContainer>

            <Typography
                size='28px'
                bold

            >
                Datos personales
            </Typography>

            <Typography
                size='14px'
                style={{ marginTop: 15 }}
            >
                ¡Mantené actualizada la información de tu perfil! De esa manera vas a tener tus datos protegidos y al día.
            </Typography>

            <BoxContainer
                primaryButtonText='Guardar'
                primaryDisabled={form.fullname !== null && form.fullname.trim() === name}
                secondaryButtonText='Cancelar'
                onClickPrimary={() => { handleUpdateFullName() }}
                onClickSecondary={() => { setForm({ fullname: name ?? '' }) }}

            >

                <Title>

                    <UserIcon size={22} color={colors.primary} />

                    <Typography
                        size='20px'
                        bold
                        style={{ marginLeft: 10 }}
                        color={colors.light}
                    >
                        Información general
                    </Typography>

                </Title>

                <Input
                    type='text'
                    label='Tu nombre y apellido'
                    value={form.fullname}
                    onChange={(e) => setForm({ fullname: e.target.value })}
                    theme='light'
                />

            </BoxContainer>

            {
                role === 'Administrador' && (

                    <>

                        {membersModal && <MembersModal setVisible={setMembersModal} />}

                        <BoxContainer>

                            <Title>

                                <UserMultipleIcon size={22} color={colors.primary} />

                                <Typography
                                    size='20px'
                                    bold
                                    style={{ marginLeft: 10 }}
                                    color={colors.light}
                                >
                                    Miembros y accesos
                                </Typography>

                            </Title>

                            <Typography
                                size='14px'
                                style={{ marginTop: 15, textAlign: 'start' }}
                                color={colors.light}
                            >
                                Acá te van a aparecer los miembros y las solicitudes de acceso de las personas que quieren ingresar a tu página.
                            </Typography>

                            <MembersButton
                                onClick={async () => {

                                    if (token !== null) {

                                        dispatch(startLoading());

                                        await startGetMembersQuery(token, dispatch);

                                        dispatch(finishLoading());

                                    }

                                    setMembersModal(true)

                                }}
                            >
                                Ver miembros y solicitudes de acceso
                            </MembersButton>

                        </BoxContainer>

                    </>

                )
            }

            <BoxContainer
            >

                <Title>

                    <Logout03Icon size={22} color={colors.primary} />

                    <Typography
                        size='20px'
                        bold
                        style={{ marginLeft: 10 }}
                        color={colors.light}
                    >
                        Cerrar Sesión
                    </Typography>

                </Title>

                <Typography
                    size='14px'
                    style={{ marginTop: 15, textAlign: 'start' }}
                    color={colors.light}
                >
                    ¿Querés cerrar sesión o ingresar con otra cuenta?
                </Typography>

                <ExitButton
                    onClick={() => {

                        showModalConfirm({
                            title: 'Atención',
                            description: 'Estas a punto de cerrar sesión.',
                            icon: 'warning',
                            confirmButtonText: 'Continuar',
                            handleConfirm: () => { handleLogOut() }
                        })
                    }}

                >Cerrar sesión de mi cuenta</ExitButton>

            </BoxContainer>

        </ScreenContainer>

    )
}
