import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useEffect, useState } from "react";
import { showMixinToast, showModalConfirm, showModalMessage } from "../../utils/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../molecules/Input";
import { formatPrice } from "../../utils/Utilities";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { MarkUpdateAsSoldProps, SoldProps } from "../../interfaces/SoldInterfaces";
import { startLoading } from "../../actions/ui";
import { startMarkAndUpdateProductAsSoldQuery } from "../../queries/SoldQueries";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 45%;
    max-width: 90%;
    max-height: 90%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 75%;
    } 


    @media ${device.md} {
        width: 95%;
        max-width: 95%;
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 21px;
        }

    }
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    & > h1 {
        width: 100%;
        text-align: left;
        white-space: normal;
        color: ${colors.grey_dark};
        margin-top: 10px;
        border-bottom: 1px solid ${colors.grey_normal};
        padding-bottom: 10px;
    }
`

const TextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > h1 {
        width: 100%;
        text-align: center;
        white-space: normal;

        @media ${device.sm} {
            font-size: 20px;
        }
    }
    
`

const SectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;

    @media ${device.sm} {
        flex-direction: column;
    } 

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.sm} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }
    
`


interface Props {
    data: SoldProps;
    setData: React.Dispatch<React.SetStateAction<SoldProps | null>>;
}

export const SoldProductModal = ({ data, setData }: Props) => {

    const { products } = useSelector((state: StoreProps) => state.fallherramientas);

    const { token } = useSelector((state: StoreProps) => state.auth);

    const [soldForm, setSoldForm] = useState<SoldProps>(data);

    const [profitForm, setProfitForm] = useState<number>((data.unitPrice * (1 + (soldForm.profit / 100))) * soldForm.amount);

    useEffect(() => {

        setProfitForm((data.unitPrice * (1 + (soldForm.profit / 100))) * soldForm.amount);

    }, [soldForm])

    const dispatch = useDispatch()

    const handleFormConfirm = async () => {

        const profitPercentage = ((profitForm - (data.unitPrice * soldForm.amount)) / data.unitPrice) * 100;

        if (profitPercentage < 0 || Number.isNaN(profitForm)) {

            showModalMessage(
                'Ups...',
                `El precio al cliente que indicaste es menor al costo. Estas indicando perdidas.`,
                'warning'
            )

        } else {

            showModalConfirm({
                title: '¿Estas seguro?',
                description: `Estas a punto de modificar los datos del producto vendido.`,
                icon: 'warning',
                confirmButtonText: 'Si, estoy seguro',
                handleConfirm: async () => {

                    dispatch(startLoading())

                    const body: MarkUpdateAsSoldProps = {
                        id: data.id,
                        amount: soldForm.amount,
                        profit: profitPercentage
                    }

                    await startMarkAndUpdateProductAsSoldQuery(body, token!, dispatch);

                    showMixinToast('Venta actualizada', 'success');

                    setData(null);

                }
            })

        }

    }

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Modificar vendido
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../../images/plus-primary-dark.png')}
                        onClick={() => { setData(null) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <TextContainer>

                    <Typography size='16px' color={colors.grey_dark}>
                        El producto vendido fué:
                    </Typography>

                    <Typography size='20px' bold color={colors.primary} style={{ margin: '15px 0px', border: `2px solid ${colors.primary_light}`, padding: 10, borderRadius: 5 }}>
                        {products.find((value) => { return value.id === soldForm.productId })?.name} [{products.find((value) => { return value.id === soldForm.productId })?.model}]
                    </Typography>

                </TextContainer>

                <ContentContainer>

                    <Typography size='16px' bold style={{ marginTop: 30 }}>
                        Verifique los datos de la venta:
                    </Typography>

                    <Input
                        type="number"
                        label={`Precio unitario ($${formatPrice(data.unitPrice * (1 + (soldForm.profit / 100)))})`}
                        value={`$${formatPrice(data.unitPrice * (1 + (soldForm.profit / 100)))} por unidad`}
                        readOnly
                        onChange={() => { }}
                        style={{ backgroundColor: colors.grey_light }}
                    />


                    <SectionContainer>
                        <Input
                            type="number"
                            label="Porcentaje de ganancia"
                            value={!Number.isNaN(soldForm.amount) ? profitForm + '%' : 'Ingrese una cantidad'}
                            readOnly
                            onChange={() => { }}
                            style={{ backgroundColor: colors.grey_light }}
                        />

                        <Input
                            type="number"
                            label={`Cantidad de productos vendidos`}
                            value={soldForm.amount ?? ''}
                            placeholder="Cantidad de stock"
                            description={!Number.isNaN(soldForm.amount) ? `Cantidad actual en stock: ${products.find((value) => { return value.id === soldForm.productId })!.stockAmount - soldForm.amount} productos` : undefined}
                            onChange={(e) => {

                                if (!Number.isNaN(e.target.value) && products.find((value) => { return value.id === soldForm.productId })!.stockAmount - parseInt(e.target.value) < 0) {

                                    showModalMessage('Ups', `No hay suficiente stock para vender`, 'warning');

                                } else {

                                    setSoldForm({ ...soldForm, amount: parseInt(e.target.value) })

                                }

                            }}
                        />

                    </SectionContainer>

                    <Input
                        type="number"
                        label={`Precio final ($${formatPrice(!Number.isNaN(profitForm) ? profitForm : 0)})`}
                        value={profitForm ?? ''}
                        onChange={(e) => {

                            setProfitForm(parseFloat(e.target.value))

                        }}
                    />

                    <TextContainer>

                        <Typography size='16px' color={colors.grey_dark}>
                            Precio final cobrado al cliente:
                        </Typography>

                        <Typography size='42px' bold color={colors.primary} style={{ margin: '15px 0px', border: `2px solid ${colors.primary_light}`, padding: 10, borderRadius: 5 }}>
                            {`AR$ ${formatPrice(!Number.isNaN(profitForm) ? profitForm : 0)}`}
                        </Typography>

                        <Typography size='16px' color={colors.grey_dark}>
                            Se va a registrar este precio final en el detalle de la venta.
                        </Typography>

                    </TextContainer>

                </ContentContainer>

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={Number.isNaN(soldForm.amount) || (profitForm === ((data.unitPrice * (1 + (soldForm.profit / 100))) * data.amount)) ? 'Modifique los datos de la venta' : 'Actualizar datos de la venta'}
                    secondaryButtonText='Cerrar'
                    primaryDisabled={Number.isNaN(soldForm.amount) || (profitForm === ((data.unitPrice * (1 + (soldForm.profit / 100))) * data.amount))}
                    secondaryDisabled={false}
                    onClickPrimary={() => {
                        handleFormConfirm()

                    }}
                    onClickSecondary={() => {
                        setData(null);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
