import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AccessProps } from "../../interfaces/AuthInterfaces";
import { MembersListView } from "../molecules/MembersListView";
import { SetAccessRoleModal } from "./SetAccessRoleModal";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { LoadingComponent } from "../molecules/LoadingComponent";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 95%;
    height: 95%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    padding-top: 120px;
    background-color: white;
    overflow-y: scroll;
    white-space: nowrap;
    position: relative;

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 93%;
    max-height: 95%;
    background-color: ${colors.light};
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 25px 50px;
    border-bottom: 1px solid ${colors.border};
    position: fixed;
    border-radius: 10px 10px 0px 0px;
    left: 10px;
    right: 10px;
    top: 2.5%;
    margin: auto;
    z-index: 2;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

interface Props {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MembersModal = ({ setVisible }: Props) => {

    const { members, checkingMembers } = useSelector((state: StoreProps) => state.fallherramientas);

    const [accessRoleModal, setAccessRoleModal] = useState<AccessProps | null>(null);

    return (

        <ShadowContainer >

            <ModalCardContainer>

                {
                    checkingMembers ?

                        (<LoadingComponent />)

                        :

                        (
                            <>

                                {accessRoleModal !== null && <SetAccessRoleModal data={accessRoleModal} setVisible={setAccessRoleModal} />}

                                <HeaderContainer>

                                    <Typography
                                        size='28px'
                                        bold
                                    >
                                        Miembros
                                    </Typography>

                                    <Button
                                        type="icon"
                                        icon={require('../../images/plus-primary-dark.png')}
                                        onClick={() => { setVisible(false) }}
                                        style={{ transform: 'rotate(45deg)', zIndex: 2 }}
                                    />

                                </HeaderContainer>

                                <MembersListView data={members.filter((value) => { return value.role !== 'None' })} setAccessRoleModal={setAccessRoleModal} />

                                {
                                    members.filter((value) => { return value.role === 'None' }).length > 0 && (

                                        <>

                                            <Typography
                                                size='24px'
                                                bold
                                                style={{
                                                    margin: '50px 0',
                                                    borderTop: `1px solid ${colors.border}`,
                                                    borderBottom: `1px solid ${colors.border}`,
                                                    padding: 20,
                                                    width: '100%',
                                                    whiteSpace: 'normal',
                                                }}
                                            >
                                                Solicitudes de acceso pendientes ({members.filter((value) => { return value.role === 'None' }).length})
                                            </Typography>

                                            <MembersListView data={members.filter((value) => { return value.role === 'None' })} setAccessRoleModal={setAccessRoleModal} />

                                        </>
                                    )
                                }

                            </>
                        )

                }



            </ModalCardContainer>

        </ShadowContainer>

    )
}
