
export const endpoints = {
    createUser: '/auth/createUser',
    updateFullname: '/auth/updateFullnameUser',
    getMembers: '/auth/members',
    setRole: '/auth/setRole',
    deleteMember: '/auth/deleteMember',
    approveAccess: '/auth/approveAccess',
    createUpdateProduct: '/product/createUpdateProduct',
    updatePrice: '/product/updatePrice',
    updateCustomProfit: '/product/updateCustomProfitManual',
    updateStock: '/product/updateStock',
    assignCodeBar: '/product/assignCodeBar',
    updateGlobalProfit: '/product/updateGlobalProfit',
    updateAllPrices: '/product/updateAllPrices',
    deleteProduct: '/product/deleteProduct',
    markUpdateSold: '/sold/markUpdateSold',
    deleteSold: '/sold/deleteSoldAndStockBack',
}
