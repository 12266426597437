import { styled } from "styled-components";
import { Typography } from "./Typography";
import { colors, device } from "../../styles/stylesConfig";

const Component = styled.button`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2px;
    margin: auto;
    border: none;
    cursor: pointer;
    background-color: transparent;

    h1 {
        
        background-color: white;
        @media ${device.sm} {
            font-size: 10px !important;
        }

        @media ${device.xs} {
            padding: 5px !important;
        }
    }

    &:hover {
        h1 {
            transition: all 0.3s ease;
            border-color: ${colors.primary} !important;
            color: ${colors.primary};

        }
    }

`
interface Props {
    text: string;
    onClick: () => void;
}

export const ActionInputButton = ({ text, onClick }: Props) => {

    return (

        <Component onClick={() => { onClick() }}>

            <Typography
                size="12px"
                color={colors.grey_dark}
                style={{
                    border: `1px solid ${colors.grey}`,
                    padding: '7px 10px',
                    borderRadius: 5,
                    textTransform: 'uppercase',
                    fontWeight: 500
                }}
            >{text}</Typography>

        </Component>

    )
}