import { useDispatch } from 'react-redux';
import { startLoginWithEmailPassword } from '../../actions/auth';
import validator from 'validator';
import { setError } from '../../actions/ui';
import { AuthTitleComponent } from '../../components/molecules/AuthTitleComponent';
import { LinkButton } from '../../components/molecules/LinkButton';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/molecules/Button';
import { Input } from '../../components/molecules/Input';

export const SignInScreen = () => {

    const dispatch = useDispatch();

    const [values, setValues] = useState<{ email: string, password: string }>({
        email: '',
        password: ''
    })

    const { email, password } = values;

    const history = useHistory()

    const handleLogin = async () => {


        if (isFormValid()) {

            dispatch(startLoginWithEmailPassword(email, password, history))

        }

    }

    const isFormValid = () => {

        if (!validator.isEmail(email)) {
            dispatch(setError('El email no es valido'));
            return false;
        } else if (password.length < 8) {
            dispatch(setError('La contraseña debe tener un mínimo de 8 caracteres'));
            return false;
        } else {
            return true;
        }

    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (

        <>

            <AuthTitleComponent
                text={`Administrá todas tus tareas en un solo lugar`}
            />

            <Input
                type='text'
                label='Tu correo electrónico'
                value={email}
                placeholder='Correo electrónico'
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        email: e.target.value.toLowerCase()
                    })
                }}
            />

            <Input
                type='password'
                label='Tu contraseña'
                value={password}
                placeholder='Contraseña'
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        password: e.target.value
                    })
                }}
            />

            <Button
                type='primary'
                text='Ingresar ahora'
                fullWidth
                onClick={() => handleLogin()}
            />

            <LinkButton
                text='¿Te olvidaste tu contraseña?'
                link='/auth/olvide-mi-contrasena'
            />

            <LinkButton
                label='¿Todavía no te creaste tu cuenta?'
                text='Registrate ahora'
                link='/auth/registrarse'
            />


        </>

    )
}
