import { SoldProps } from '../interfaces/SoldInterfaces';
import { formatPrice, formatMonthYearText } from '../utils/Utilities';

export const filterStatisticDates = (list: SoldProps[]) => {

    let newListDates: string[] = []

    // Filtrar las fechas existentes

    list.forEach((value) => {

        const date: string = value.date.toString().substring(0, 6)

        if (!newListDates.includes(date)) {
            newListDates = [
                ...newListDates,
                date
            ];
        }

    })

    return newListDates.sort(function (a, b) { return parseInt(b) - parseInt(a) });

}

export const filterStatisticProducts = (list: SoldProps[], yearMonth: string) => {

    let newListProducts: SoldProps[] = []

    // Filtrar las fechas existentes

    list.forEach((value) => {

        const date: string = value.date.toString().substring(0, 6)

        if (yearMonth === date) {
            newListProducts = [
                ...newListProducts,
                value
            ];
        }

    })

    return newListProducts;

}


export const getProfitMonth = (list: SoldProps[]) => {

    let profitMonth: number = 0;

    list.forEach((value) => {

        profitMonth += (value.unitPrice * (value.profit / 100) * value.amount);

    })

    return `$${formatPrice(profitMonth)}`;

}

export const getProfitVsPreviousMonth = (list: SoldProps[], yearMonth: string) => {

    const datesList = filterStatisticDates(list).sort(function (a, b) { return parseInt(b) - parseInt(a) });

    const currencyMonthIndex = datesList.findIndex((value) => { return value === yearMonth })

    if (currencyMonthIndex === datesList.length - 1) return { previousMonth: '', percentage: '+100%' };

    const previousMonthIndex = datesList[currencyMonthIndex + 1]

    const profitCurrencyMonth = parseInt(getProfitMonth(filterStatisticProducts(list, yearMonth)).replace('$', "").replace(/\./g, "").replace(/\,/g, ""));

    const profitPreviousMonth = parseInt(getProfitMonth(filterStatisticProducts(list, previousMonthIndex)).replace('$', "").replace(/\./g, "").replace(/\,/g, ""));

    let profitPercentage = ''

    if (profitCurrencyMonth === profitPreviousMonth) return { previousMonth: `(${formatMonthYearText(parseInt(previousMonthIndex))})`, percentage: '+0%' };

    if (profitCurrencyMonth > profitPreviousMonth) {

        profitPercentage = `+${profitPreviousMonth * 100 / profitCurrencyMonth}%`;
    } else {
        profitPercentage = `-${profitCurrencyMonth * 100 / profitPreviousMonth}%`;
    }


    return { previousMonth: `(${formatMonthYearText(parseInt(previousMonthIndex))})`, percentage: profitPercentage };

}