import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { Button } from "../molecules/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useState } from "react";
import { showMixinToast, showModalConfirm } from "../../utils/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../molecules/Input";
import { ProductProps } from "../../interfaces/ProductInterfaces";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { startLoading } from "../../actions/ui";
import { startUpdateStockQuery } from "../../queries/ProductQueries";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 45%;
    max-width: 90%;
    max-height: 75%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 75%;
    } 


    @media ${device.md} {
        width: 95%;
        max-width: 95%;
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 21px;
        }

    }
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    & > h1 {
        width: 100%;
        text-align: left;
        white-space: normal;
        color: ${colors.grey_dark};
        margin-top: 10px;
        border-bottom: 1px solid ${colors.grey_normal};
        padding-bottom: 10px;
    }
`

const TextContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > h1 {
        width: 100%;
        text-align: center;
        white-space: normal;
    }
    
`


interface Props {
    data: ProductProps;
    setData: React.Dispatch<React.SetStateAction<ProductProps | null>>;
    onConfirm: (product: ProductProps) => void;
}

export const UpdateProductStockModal = ({ data, setData, onConfirm }: Props) => {

    const [stockForm, setStockForm] = useState<number>(data.stockAmount);

    const { token } = useSelector((state: StoreProps) => state.auth);

    const [fieldIncomplete, setFieldIncomplete] = useState({
        stock: false,
    })

    const dispatch = useDispatch()

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            stock: data.stockAmount === stockForm,
        });

        if (data.stockAmount === stockForm || Number.isNaN(stockForm) || !Number.isInteger(stockForm)) {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            showModalConfirm({
                title: '¿Estas seguro?',
                description: `Estas a punto de indicar que el stock disponible del producto va a pasar de ${data.stockAmount} a ${stockForm}.`,
                icon: 'warning',
                confirmButtonText: 'Si, estoy seguro',
                handleConfirm: async () => {

                    dispatch(startLoading())

                    const body = {
                        id: data.id,
                        stockAmount: Number.isNaN(stockForm) ? 0 : stockForm,
                    }

                    await startUpdateStockQuery(body, token!, dispatch);

                    showMixinToast('Stock actualizado', 'success');

                    setData(null);

                    onConfirm({ ...data, stockAmount: stockForm })

                }
            })

        }

    }

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Actualizar stock
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../../images/plus-primary-dark.png')}
                        onClick={() => { setData(null) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <TextContainer>

                    <Typography size='16px' color={colors.grey_dark}>
                        El stock actual del producto es de:
                    </Typography>

                    <Typography size='42px' bold color={colors.primary} style={{ margin: '15px 0px', border: `2px solid ${colors.primary_light}`, padding: 10, borderRadius: 5 }}>
                        {data.stockAmount} productos
                    </Typography>

                </TextContainer>

                <ContentContainer>

                    <Typography size='16px' bold style={{ marginTop: 30 }}>
                        Indique el nuevo stock del producto:
                    </Typography>

                    <Input
                        type="number"
                        label={`Nueva cantidad de stock`}
                        value={stockForm ?? ''}
                        placeholder="Cantidad de stock"
                        description={data.stockAmount === stockForm ? '' : `Vas a tener un stock de ${!Number.isNaN(stockForm) ? stockForm : 0} productos`}
                        incomplete={fieldIncomplete.stock}
                        onChange={(e) => {

                            // if (/^\d+$/.test(e.target.value)) {

                            setFieldIncomplete({ ...fieldIncomplete, stock: false });

                            setStockForm(parseInt(e.target.value))

                            // }

                        }}
                    />


                </ContentContainer>

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={data.stockAmount === stockForm || Number.isNaN(stockForm) ? 'Indique el nuevo stock' : 'Actualizar stock del producto'}
                    secondaryButtonText='Cerrar'
                    primaryDisabled={data.stockAmount === stockForm || Number.isNaN(stockForm)}
                    secondaryDisabled={false}
                    onClickPrimary={() => {

                        // Funcion a back para actualizar

                        handleFormConfirm()


                    }}
                    onClickSecondary={() => {
                        setData(null);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
